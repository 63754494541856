const portraitMock = {
  result: {
    data: {
      categoryBySlug: {
        id: "3",
        name: "People",
        slug: "portrait",
        cover: {
          formats: {
            large: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897745/large_DSC_6857_0bc1f70fa6.jpg",
              hash: "large_DSC_6857_0bc1f70fa6",
              mime: "image/jpeg",
              name: "large_DSC_6857.jpg",
              path: null,
              size: 53.9,
              width: 665,
              height: 1000,
              provider_metadata: {
                public_id: "large_DSC_6857_0bc1f70fa6",
                resource_type: "image",
              },
            },
            small: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897746/small_DSC_6857_0bc1f70fa6.jpg",
              hash: "small_DSC_6857_0bc1f70fa6",
              mime: "image/jpeg",
              name: "small_DSC_6857.jpg",
              path: null,
              size: 16.52,
              width: 333,
              height: 500,
              provider_metadata: {
                public_id: "small_DSC_6857_0bc1f70fa6",
                resource_type: "image",
              },
            },
            medium: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897746/medium_DSC_6857_0bc1f70fa6.jpg",
              hash: "medium_DSC_6857_0bc1f70fa6",
              mime: "image/jpeg",
              name: "medium_DSC_6857.jpg",
              path: null,
              size: 31.54,
              width: 499,
              height: 750,
              provider_metadata: {
                public_id: "medium_DSC_6857_0bc1f70fa6",
                resource_type: "image",
              },
            },
            thumbnail: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897745/thumbnail_DSC_6857_0bc1f70fa6.jpg",
              hash: "thumbnail_DSC_6857_0bc1f70fa6",
              mime: "image/jpeg",
              name: "thumbnail_DSC_6857.jpg",
              path: null,
              size: 3.06,
              width: 104,
              height: 156,
              provider_metadata: {
                public_id: "thumbnail_DSC_6857_0bc1f70fa6",
                resource_type: "image",
              },
            },
          },
          __typename: "UploadFile",
        },
        albums: [
          {
            id: "5",
            title: "Studio session",
            slug: "studio-session",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607012645/large_DSC_6975_40f359f2b6.jpg",
                  hash: "large_DSC_6975_40f359f2b6",
                  mime: "image/jpeg",
                  name: "large_DSC_6975.jpg",
                  path: null,
                  size: 46.32,
                  width: 665,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_DSC_6975_40f359f2b6",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607012646/small_DSC_6975_40f359f2b6.jpg",
                  hash: "small_DSC_6975_40f359f2b6",
                  mime: "image/jpeg",
                  name: "small_DSC_6975.jpg",
                  path: null,
                  size: 15.58,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_DSC_6975_40f359f2b6",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607012645/medium_DSC_6975_40f359f2b6.jpg",
                  hash: "medium_DSC_6975_40f359f2b6",
                  mime: "image/jpeg",
                  name: "medium_DSC_6975.jpg",
                  path: null,
                  size: 28.68,
                  width: 499,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_DSC_6975_40f359f2b6",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607012642/thumbnail_DSC_6975_40f359f2b6.jpg",
                  hash: "thumbnail_DSC_6975_40f359f2b6",
                  mime: "image/jpeg",
                  name: "thumbnail_DSC_6975.jpg",
                  path: null,
                  size: 2.9,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_DSC_6975_40f359f2b6",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Studio session",
              name: "Studio session",
              images: [
                {
                  id: "162",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897358/large_DSC_6454_3af9ed4a2e.jpg",
                      hash: "large_DSC_6454_3af9ed4a2e",
                      mime: "image/jpeg",
                      name: "large_DSC_6454.jpg",
                      path: null,
                      size: 33.7,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_6454_3af9ed4a2e",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897359/small_DSC_6454_3af9ed4a2e.jpg",
                      hash: "small_DSC_6454_3af9ed4a2e",
                      mime: "image/jpeg",
                      name: "small_DSC_6454.jpg",
                      path: null,
                      size: 10.52,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_6454_3af9ed4a2e",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897358/medium_DSC_6454_3af9ed4a2e.jpg",
                      hash: "medium_DSC_6454_3af9ed4a2e",
                      mime: "image/jpeg",
                      name: "medium_DSC_6454.jpg",
                      path: null,
                      size: 20.35,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_6454_3af9ed4a2e",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897357/thumbnail_DSC_6454_3af9ed4a2e.jpg",
                      hash: "thumbnail_DSC_6454_3af9ed4a2e",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_6454.jpg",
                      path: null,
                      size: 2.22,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_6454_3af9ed4a2e",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "165",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897412/large_IMG_5312_d273fd539f.jpg",
                      hash: "large_IMG_5312_d273fd539f",
                      mime: "image/jpeg",
                      name: "large_IMG_5312.JPG",
                      path: null,
                      size: 59.94,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_IMG_5312_d273fd539f",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897412/small_IMG_5312_d273fd539f.jpg",
                      hash: "small_IMG_5312_d273fd539f",
                      mime: "image/jpeg",
                      name: "small_IMG_5312.JPG",
                      path: null,
                      size: 12.49,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_IMG_5312_d273fd539f",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897412/medium_IMG_5312_d273fd539f.jpg",
                      hash: "medium_IMG_5312_d273fd539f",
                      mime: "image/jpeg",
                      name: "medium_IMG_5312.JPG",
                      path: null,
                      size: 29.72,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_IMG_5312_d273fd539f",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897411/thumbnail_IMG_5312_d273fd539f.jpg",
                      hash: "thumbnail_IMG_5312_d273fd539f",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5312.JPG",
                      path: null,
                      size: 1.92,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5312_d273fd539f",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "163",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897374/large_DSC_8569_930d1ac4cc.jpg",
                      hash: "large_DSC_8569_930d1ac4cc",
                      mime: "image/jpeg",
                      name: "large_DSC_8569.jpg",
                      path: null,
                      size: 48.48,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_8569_930d1ac4cc",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897375/small_DSC_8569_930d1ac4cc.jpg",
                      hash: "small_DSC_8569_930d1ac4cc",
                      mime: "image/jpeg",
                      name: "small_DSC_8569.jpg",
                      path: null,
                      size: 15.3,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_8569_930d1ac4cc",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897375/medium_DSC_8569_930d1ac4cc.jpg",
                      hash: "medium_DSC_8569_930d1ac4cc",
                      mime: "image/jpeg",
                      name: "medium_DSC_8569.jpg",
                      path: null,
                      size: 28.87,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_8569_930d1ac4cc",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897373/thumbnail_DSC_8569_930d1ac4cc.jpg",
                      hash: "thumbnail_DSC_8569_930d1ac4cc",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_8569.jpg",
                      path: null,
                      size: 3.32,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_8569_930d1ac4cc",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "161",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897350/large_DSC_1685_4cc9888305.jpg",
                      hash: "large_DSC_1685_4cc9888305",
                      mime: "image/jpeg",
                      name: "large_DSC_1685.JPG",
                      path: null,
                      size: 37.37,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_1685_4cc9888305",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897351/small_DSC_1685_4cc9888305.jpg",
                      hash: "small_DSC_1685_4cc9888305",
                      mime: "image/jpeg",
                      name: "small_DSC_1685.JPG",
                      path: null,
                      size: 12.66,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_1685_4cc9888305",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897350/medium_DSC_1685_4cc9888305.jpg",
                      hash: "medium_DSC_1685_4cc9888305",
                      mime: "image/jpeg",
                      name: "medium_DSC_1685.JPG",
                      path: null,
                      size: 23.26,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_1685_4cc9888305",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897350/thumbnail_DSC_1685_4cc9888305.jpg",
                      hash: "thumbnail_DSC_1685_4cc9888305",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_1685.JPG",
                      path: null,
                      size: 2.49,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_1685_4cc9888305",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "170",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897488/large_DSC_3214_dcc04daa6b.jpg",
                      hash: "large_DSC_3214_dcc04daa6b",
                      mime: "image/jpeg",
                      name: "large_DSC_3214.JPG",
                      path: null,
                      size: 58.87,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3214_dcc04daa6b",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897489/small_DSC_3214_dcc04daa6b.jpg",
                      hash: "small_DSC_3214_dcc04daa6b",
                      mime: "image/jpeg",
                      name: "small_DSC_3214.JPG",
                      path: null,
                      size: 19.11,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3214_dcc04daa6b",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897489/medium_DSC_3214_dcc04daa6b.jpg",
                      hash: "medium_DSC_3214_dcc04daa6b",
                      mime: "image/jpeg",
                      name: "medium_DSC_3214.JPG",
                      path: null,
                      size: 35.59,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3214_dcc04daa6b",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897488/thumbnail_DSC_3214_dcc04daa6b.jpg",
                      hash: "thumbnail_DSC_3214_dcc04daa6b",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3214.JPG",
                      path: null,
                      size: 3.53,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3214_dcc04daa6b",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "169",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897483/large_DSC_3105_4de33b578f.jpg",
                      hash: "large_DSC_3105_4de33b578f",
                      mime: "image/jpeg",
                      name: "large_DSC_3105.JPG",
                      path: null,
                      size: 39.55,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3105_4de33b578f",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897484/small_DSC_3105_4de33b578f.jpg",
                      hash: "small_DSC_3105_4de33b578f",
                      mime: "image/jpeg",
                      name: "small_DSC_3105.JPG",
                      path: null,
                      size: 10.78,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3105_4de33b578f",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897483/medium_DSC_3105_4de33b578f.jpg",
                      hash: "medium_DSC_3105_4de33b578f",
                      mime: "image/jpeg",
                      name: "medium_DSC_3105.JPG",
                      path: null,
                      size: 21.65,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3105_4de33b578f",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897482/thumbnail_DSC_3105_4de33b578f.jpg",
                      hash: "thumbnail_DSC_3105_4de33b578f",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3105.JPG",
                      path: null,
                      size: 2.13,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3105_4de33b578f",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "166",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897465/large_DSC_03621_7cb268f842.jpg",
                      hash: "large_DSC_03621_7cb268f842",
                      mime: "image/jpeg",
                      name: "large_DSC03621.JPG",
                      path: null,
                      size: 50.16,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_03621_7cb268f842",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897466/small_DSC_03621_7cb268f842.jpg",
                      hash: "small_DSC_03621_7cb268f842",
                      mime: "image/jpeg",
                      name: "small_DSC03621.JPG",
                      path: null,
                      size: 16.21,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_03621_7cb268f842",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897466/medium_DSC_03621_7cb268f842.jpg",
                      hash: "medium_DSC_03621_7cb268f842",
                      mime: "image/jpeg",
                      name: "medium_DSC03621.JPG",
                      path: null,
                      size: 30.71,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_03621_7cb268f842",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897464/thumbnail_DSC_03621_7cb268f842.jpg",
                      hash: "thumbnail_DSC_03621_7cb268f842",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC03621.JPG",
                      path: null,
                      size: 2.9,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_03621_7cb268f842",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "168",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897474/large_IMG_5311_124b84c7e7.jpg",
                      hash: "large_IMG_5311_124b84c7e7",
                      mime: "image/jpeg",
                      name: "large_IMG_5311.JPG",
                      path: null,
                      size: 38.04,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5311_124b84c7e7",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897474/small_IMG_5311_124b84c7e7.jpg",
                      hash: "small_IMG_5311_124b84c7e7",
                      mime: "image/jpeg",
                      name: "small_IMG_5311.JPG",
                      path: null,
                      size: 13.52,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5311_124b84c7e7",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897474/medium_IMG_5311_124b84c7e7.jpg",
                      hash: "medium_IMG_5311_124b84c7e7",
                      mime: "image/jpeg",
                      name: "medium_IMG_5311.JPG",
                      path: null,
                      size: 24.44,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5311_124b84c7e7",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897473/thumbnail_IMG_5311_124b84c7e7.jpg",
                      hash: "thumbnail_IMG_5311_124b84c7e7",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5311.JPG",
                      path: null,
                      size: 4.87,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5311_124b84c7e7",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "365",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1608014275/large_DSC_02435_1cdfb946d0.jpg",
                      hash: "large_DSC_02435_1cdfb946d0",
                      mime: "image/jpeg",
                      name: "large_DSC02435.jpg",
                      path: null,
                      size: 45.7,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_02435_1cdfb946d0",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1608014276/small_DSC_02435_1cdfb946d0.jpg",
                      hash: "small_DSC_02435_1cdfb946d0",
                      mime: "image/jpeg",
                      name: "small_DSC02435.jpg",
                      path: null,
                      size: 17.01,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_02435_1cdfb946d0",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1608014276/medium_DSC_02435_1cdfb946d0.jpg",
                      hash: "medium_DSC_02435_1cdfb946d0",
                      mime: "image/jpeg",
                      name: "medium_DSC02435.jpg",
                      path: null,
                      size: 29.97,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_02435_1cdfb946d0",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1608014275/thumbnail_DSC_02435_1cdfb946d0.jpg",
                      hash: "thumbnail_DSC_02435_1cdfb946d0",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC02435.jpg",
                      path: null,
                      size: 3.4,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_02435_1cdfb946d0",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
        ],
        __typename: "Category",
      },
    },
  },
};

export default portraitMock;
