const categoriesMock = {
  result: {
    data: {
      categories: [
        {
          id: "2",
          name: "Kids and Families",
          slug: "kids-and-families",
          position: 1,
          cover: {
            formats: {
              large: {
                ext: ".JPG",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714976/large_DSC_4867_4a42d270f7.jpg",
                hash: "large_DSC_4867_4a42d270f7",
                mime: "image/jpeg",
                name: "large_DSC_4867.JPG",
                path: null,
                size: 67.12,
                width: 665,
                height: 1000,
                provider_metadata: {
                  public_id: "large_DSC_4867_4a42d270f7",
                  resource_type: "image",
                },
              },
              small: {
                ext: ".JPG",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714977/small_DSC_4867_4a42d270f7.jpg",
                hash: "small_DSC_4867_4a42d270f7",
                mime: "image/jpeg",
                name: "small_DSC_4867.JPG",
                path: null,
                size: 22.61,
                width: 333,
                height: 500,
                provider_metadata: {
                  public_id: "small_DSC_4867_4a42d270f7",
                  resource_type: "image",
                },
              },
              medium: {
                ext: ".JPG",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714976/medium_DSC_4867_4a42d270f7.jpg",
                hash: "medium_DSC_4867_4a42d270f7",
                mime: "image/jpeg",
                name: "medium_DSC_4867.JPG",
                path: null,
                size: 41.49,
                width: 499,
                height: 750,
                provider_metadata: {
                  public_id: "medium_DSC_4867_4a42d270f7",
                  resource_type: "image",
                },
              },
              thumbnail: {
                ext: ".JPG",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714975/thumbnail_DSC_4867_4a42d270f7.jpg",
                hash: "thumbnail_DSC_4867_4a42d270f7",
                mime: "image/jpeg",
                name: "thumbnail_DSC_4867.JPG",
                path: null,
                size: 4.09,
                width: 104,
                height: 156,
                provider_metadata: {
                  public_id: "thumbnail_DSC_4867_4a42d270f7",
                  resource_type: "image",
                },
              },
            },
            __typename: "UploadFile",
          },
          __typename: "Category",
        },
        {
          id: "3",
          name: "People",
          slug: "portrait",
          position: 2,
          cover: {
            formats: {
              large: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897745/large_DSC_6857_0bc1f70fa6.jpg",
                hash: "large_DSC_6857_0bc1f70fa6",
                mime: "image/jpeg",
                name: "large_DSC_6857.jpg",
                path: null,
                size: 53.9,
                width: 665,
                height: 1000,
                provider_metadata: {
                  public_id: "large_DSC_6857_0bc1f70fa6",
                  resource_type: "image",
                },
              },
              small: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897746/small_DSC_6857_0bc1f70fa6.jpg",
                hash: "small_DSC_6857_0bc1f70fa6",
                mime: "image/jpeg",
                name: "small_DSC_6857.jpg",
                path: null,
                size: 16.52,
                width: 333,
                height: 500,
                provider_metadata: {
                  public_id: "small_DSC_6857_0bc1f70fa6",
                  resource_type: "image",
                },
              },
              medium: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897746/medium_DSC_6857_0bc1f70fa6.jpg",
                hash: "medium_DSC_6857_0bc1f70fa6",
                mime: "image/jpeg",
                name: "medium_DSC_6857.jpg",
                path: null,
                size: 31.54,
                width: 499,
                height: 750,
                provider_metadata: {
                  public_id: "medium_DSC_6857_0bc1f70fa6",
                  resource_type: "image",
                },
              },
              thumbnail: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606897745/thumbnail_DSC_6857_0bc1f70fa6.jpg",
                hash: "thumbnail_DSC_6857_0bc1f70fa6",
                mime: "image/jpeg",
                name: "thumbnail_DSC_6857.jpg",
                path: null,
                size: 3.06,
                width: 104,
                height: 156,
                provider_metadata: {
                  public_id: "thumbnail_DSC_6857_0bc1f70fa6",
                  resource_type: "image",
                },
              },
            },
            __typename: "UploadFile",
          },
          __typename: "Category",
        },
        {
          id: "1",
          name: "Couples",
          slug: "couple",
          position: 3,
          cover: {
            formats: {
              large: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607001630/large_DSC_7416_87f94ae1da.jpg",
                hash: "large_DSC_7416_87f94ae1da",
                mime: "image/jpeg",
                name: "large_DSC_7416.jpg",
                path: null,
                size: 70,
                width: 665,
                height: 1000,
                provider_metadata: {
                  public_id: "large_DSC_7416_87f94ae1da",
                  resource_type: "image",
                },
              },
              small: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607001631/small_DSC_7416_87f94ae1da.jpg",
                hash: "small_DSC_7416_87f94ae1da",
                mime: "image/jpeg",
                name: "small_DSC_7416.jpg",
                path: null,
                size: 22.9,
                width: 333,
                height: 500,
                provider_metadata: {
                  public_id: "small_DSC_7416_87f94ae1da",
                  resource_type: "image",
                },
              },
              medium: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607001631/medium_DSC_7416_87f94ae1da.jpg",
                hash: "medium_DSC_7416_87f94ae1da",
                mime: "image/jpeg",
                name: "medium_DSC_7416.jpg",
                path: null,
                size: 42.4,
                width: 499,
                height: 750,
                provider_metadata: {
                  public_id: "medium_DSC_7416_87f94ae1da",
                  resource_type: "image",
                },
              },
              thumbnail: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607001629/thumbnail_DSC_7416_87f94ae1da.jpg",
                hash: "thumbnail_DSC_7416_87f94ae1da",
                mime: "image/jpeg",
                name: "thumbnail_DSC_7416.jpg",
                path: null,
                size: 3.91,
                width: 104,
                height: 156,
                provider_metadata: {
                  public_id: "thumbnail_DSC_7416_87f94ae1da",
                  resource_type: "image",
                },
              },
            },
            __typename: "UploadFile",
          },
          __typename: "Category",
        },
        {
          id: "4",
          name: "Fashion",
          slug: "fashion",
          position: 4,
          cover: {
            formats: {
              large: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013082/large_DSC_3517_6f485abe90.jpg",
                hash: "large_DSC_3517_6f485abe90",
                mime: "image/jpeg",
                name: "large_DSC_3517.jpg",
                path: null,
                size: 29.32,
                width: 665,
                height: 1000,
                provider_metadata: {
                  public_id: "large_DSC_3517_6f485abe90",
                  resource_type: "image",
                },
              },
              small: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013084/small_DSC_3517_6f485abe90.jpg",
                hash: "small_DSC_3517_6f485abe90",
                mime: "image/jpeg",
                name: "small_DSC_3517.jpg",
                path: null,
                size: 11.11,
                width: 333,
                height: 500,
                provider_metadata: {
                  public_id: "small_DSC_3517_6f485abe90",
                  resource_type: "image",
                },
              },
              medium: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/medium_DSC_3517_6f485abe90.jpg",
                hash: "medium_DSC_3517_6f485abe90",
                mime: "image/jpeg",
                name: "medium_DSC_3517.jpg",
                path: null,
                size: 19.4,
                width: 499,
                height: 750,
                provider_metadata: {
                  public_id: "medium_DSC_3517_6f485abe90",
                  resource_type: "image",
                },
              },
              thumbnail: {
                ext: ".jpg",
                url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013078/thumbnail_DSC_3517_6f485abe90.jpg",
                hash: "thumbnail_DSC_3517_6f485abe90",
                mime: "image/jpeg",
                name: "thumbnail_DSC_3517.jpg",
                path: null,
                size: 2.31,
                width: 104,
                height: 156,
                provider_metadata: {
                  public_id: "thumbnail_DSC_3517_6f485abe90",
                  resource_type: "image",
                },
              },
            },
            __typename: "UploadFile",
          },
          __typename: "Category",
        },
      ],
    },
  },
};

export default categoriesMock;
