const fashionMock = {
  result: {
    data: {
      categoryBySlug: {
        id: "4",
        name: "Fashion",
        slug: "fashion",
        cover: {
          formats: {
            large: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013082/large_DSC_3517_6f485abe90.jpg",
              hash: "large_DSC_3517_6f485abe90",
              mime: "image/jpeg",
              name: "large_DSC_3517.jpg",
              path: null,
              size: 29.32,
              width: 665,
              height: 1000,
              provider_metadata: {
                public_id: "large_DSC_3517_6f485abe90",
                resource_type: "image",
              },
            },
            small: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013084/small_DSC_3517_6f485abe90.jpg",
              hash: "small_DSC_3517_6f485abe90",
              mime: "image/jpeg",
              name: "small_DSC_3517.jpg",
              path: null,
              size: 11.11,
              width: 333,
              height: 500,
              provider_metadata: {
                public_id: "small_DSC_3517_6f485abe90",
                resource_type: "image",
              },
            },
            medium: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/medium_DSC_3517_6f485abe90.jpg",
              hash: "medium_DSC_3517_6f485abe90",
              mime: "image/jpeg",
              name: "medium_DSC_3517.jpg",
              path: null,
              size: 19.4,
              width: 499,
              height: 750,
              provider_metadata: {
                public_id: "medium_DSC_3517_6f485abe90",
                resource_type: "image",
              },
            },
            thumbnail: {
              ext: ".jpg",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013078/thumbnail_DSC_3517_6f485abe90.jpg",
              hash: "thumbnail_DSC_3517_6f485abe90",
              mime: "image/jpeg",
              name: "thumbnail_DSC_3517.jpg",
              path: null,
              size: 2.31,
              width: 104,
              height: 156,
              provider_metadata: {
                public_id: "thumbnail_DSC_3517_6f485abe90",
                resource_type: "image",
              },
            },
          },
          __typename: "UploadFile",
        },
        albums: [
          {
            id: "15",
            title: "Olivia Ng Bridal lookbook",
            slug: "olivia-ng-bridal-lookbook",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013502/large_cover_fa3aad82ae.jpg",
                  hash: "large_cover_fa3aad82ae",
                  mime: "image/jpeg",
                  name: "large_cover.jpg",
                  path: null,
                  size: 57.45,
                  width: 665,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_cover_fa3aad82ae",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013503/small_cover_fa3aad82ae.jpg",
                  hash: "small_cover_fa3aad82ae",
                  mime: "image/jpeg",
                  name: "small_cover.jpg",
                  path: null,
                  size: 18.19,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_cover_fa3aad82ae",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013503/medium_cover_fa3aad82ae.jpg",
                  hash: "medium_cover_fa3aad82ae",
                  mime: "image/jpeg",
                  name: "medium_cover.jpg",
                  path: null,
                  size: 34.86,
                  width: 499,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_cover_fa3aad82ae",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013501/thumbnail_cover_fa3aad82ae.jpg",
                  hash: "thumbnail_cover_fa3aad82ae",
                  mime: "image/jpeg",
                  name: "thumbnail_cover.jpg",
                  path: null,
                  size: 2.84,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_cover_fa3aad82ae",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Olivia Ng Bridal lookbook",
              name: "Olivia Ng Bridal lookbook",
              images: [
                {
                  id: "339",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/large_DSC_3823_6061826918.jpg",
                      hash: "large_DSC_3823_6061826918",
                      mime: "image/jpeg",
                      name: "large_DSC_3823.jpg",
                      path: null,
                      size: 50.08,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3823_6061826918",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013085/small_DSC_3823_6061826918.jpg",
                      hash: "small_DSC_3823_6061826918",
                      mime: "image/jpeg",
                      name: "small_DSC_3823.jpg",
                      path: null,
                      size: 17.53,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3823_6061826918",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013084/medium_DSC_3823_6061826918.jpg",
                      hash: "medium_DSC_3823_6061826918",
                      mime: "image/jpeg",
                      name: "medium_DSC_3823.jpg",
                      path: null,
                      size: 31.83,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3823_6061826918",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013079/thumbnail_DSC_3823_6061826918.jpg",
                      hash: "thumbnail_DSC_3823_6061826918",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3823.jpg",
                      path: null,
                      size: 3.22,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3823_6061826918",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "338",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/large_DSC_3434_e5448c0534.jpg",
                      hash: "large_DSC_3434_e5448c0534",
                      mime: "image/jpeg",
                      name: "large_DSC_3434.jpg",
                      path: null,
                      size: 62,
                      width: 666,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3434_e5448c0534",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013085/small_DSC_3434_e5448c0534.jpg",
                      hash: "small_DSC_3434_e5448c0534",
                      mime: "image/jpeg",
                      name: "small_DSC_3434.jpg",
                      path: null,
                      size: 19.46,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3434_e5448c0534",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013084/medium_DSC_3434_e5448c0534.jpg",
                      hash: "medium_DSC_3434_e5448c0534",
                      mime: "image/jpeg",
                      name: "medium_DSC_3434.jpg",
                      path: null,
                      size: 37.21,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3434_e5448c0534",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013081/thumbnail_DSC_3434_e5448c0534.jpg",
                      hash: "thumbnail_DSC_3434_e5448c0534",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3434.jpg",
                      path: null,
                      size: 3.48,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3434_e5448c0534",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "340",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013085/large_DSC_3439_f36f63e924.jpg",
                      hash: "large_DSC_3439_f36f63e924",
                      mime: "image/jpeg",
                      name: "large_DSC_3439.jpg",
                      path: null,
                      size: 49.68,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3439_f36f63e924",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013086/small_DSC_3439_f36f63e924.jpg",
                      hash: "small_DSC_3439_f36f63e924",
                      mime: "image/jpeg",
                      name: "small_DSC_3439.jpg",
                      path: null,
                      size: 19.42,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3439_f36f63e924",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013086/medium_DSC_3439_f36f63e924.jpg",
                      hash: "medium_DSC_3439_f36f63e924",
                      mime: "image/jpeg",
                      name: "medium_DSC_3439.jpg",
                      path: null,
                      size: 33.32,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3439_f36f63e924",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/thumbnail_DSC_3439_f36f63e924.jpg",
                      hash: "thumbnail_DSC_3439_f36f63e924",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3439.jpg",
                      path: null,
                      size: 4.03,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3439_f36f63e924",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "337",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013082/large_DSC_3517_6f485abe90.jpg",
                      hash: "large_DSC_3517_6f485abe90",
                      mime: "image/jpeg",
                      name: "large_DSC_3517.jpg",
                      path: null,
                      size: 29.32,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3517_6f485abe90",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013084/small_DSC_3517_6f485abe90.jpg",
                      hash: "small_DSC_3517_6f485abe90",
                      mime: "image/jpeg",
                      name: "small_DSC_3517.jpg",
                      path: null,
                      size: 11.11,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3517_6f485abe90",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/medium_DSC_3517_6f485abe90.jpg",
                      hash: "medium_DSC_3517_6f485abe90",
                      mime: "image/jpeg",
                      name: "medium_DSC_3517.jpg",
                      path: null,
                      size: 19.4,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3517_6f485abe90",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013078/thumbnail_DSC_3517_6f485abe90.jpg",
                      hash: "thumbnail_DSC_3517_6f485abe90",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3517.jpg",
                      path: null,
                      size: 2.31,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3517_6f485abe90",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "336",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013081/large_DSC_3777_37fbc29607.jpg",
                      hash: "large_DSC_3777_37fbc29607",
                      mime: "image/jpeg",
                      name: "large_DSC_3777.jpg",
                      path: null,
                      size: 45.79,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3777_37fbc29607",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013083/small_DSC_3777_37fbc29607.jpg",
                      hash: "small_DSC_3777_37fbc29607",
                      mime: "image/jpeg",
                      name: "small_DSC_3777.jpg",
                      path: null,
                      size: 15.48,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3777_37fbc29607",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013082/medium_DSC_3777_37fbc29607.jpg",
                      hash: "medium_DSC_3777_37fbc29607",
                      mime: "image/jpeg",
                      name: "medium_DSC_3777.jpg",
                      path: null,
                      size: 28.56,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3777_37fbc29607",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013078/thumbnail_DSC_3777_37fbc29607.jpg",
                      hash: "thumbnail_DSC_3777_37fbc29607",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3777.jpg",
                      path: null,
                      size: 2.88,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3777_37fbc29607",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "342",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013097/large_DSC_3323_2a3a7b7955.jpg",
                      hash: "large_DSC_3323_2a3a7b7955",
                      mime: "image/jpeg",
                      name: "large_DSC_3323.jpg",
                      path: null,
                      size: 52.55,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3323_2a3a7b7955",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013097/small_DSC_3323_2a3a7b7955.jpg",
                      hash: "small_DSC_3323_2a3a7b7955",
                      mime: "image/jpeg",
                      name: "small_DSC_3323.jpg",
                      path: null,
                      size: 16.77,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3323_2a3a7b7955",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013097/medium_DSC_3323_2a3a7b7955.jpg",
                      hash: "medium_DSC_3323_2a3a7b7955",
                      mime: "image/jpeg",
                      name: "medium_DSC_3323.jpg",
                      path: null,
                      size: 31.78,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3323_2a3a7b7955",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013096/thumbnail_DSC_3323_2a3a7b7955.jpg",
                      hash: "thumbnail_DSC_3323_2a3a7b7955",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3323.jpg",
                      path: null,
                      size: 2.92,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3323_2a3a7b7955",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "344",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013116/large_DSC_3897_6f2efde13f.jpg",
                      hash: "large_DSC_3897_6f2efde13f",
                      mime: "image/jpeg",
                      name: "large_DSC_3897.jpg",
                      path: null,
                      size: 36.26,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3897_6f2efde13f",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013117/small_DSC_3897_6f2efde13f.jpg",
                      hash: "small_DSC_3897_6f2efde13f",
                      mime: "image/jpeg",
                      name: "small_DSC_3897.jpg",
                      path: null,
                      size: 14.01,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3897_6f2efde13f",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013117/medium_DSC_3897_6f2efde13f.jpg",
                      hash: "medium_DSC_3897_6f2efde13f",
                      mime: "image/jpeg",
                      name: "medium_DSC_3897.jpg",
                      path: null,
                      size: 24.14,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3897_6f2efde13f",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013115/thumbnail_DSC_3897_6f2efde13f.jpg",
                      hash: "thumbnail_DSC_3897_6f2efde13f",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3897.jpg",
                      path: null,
                      size: 3.03,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3897_6f2efde13f",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "341",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013088/large_DSC_3488_af905f4375.jpg",
                      hash: "large_DSC_3488_af905f4375",
                      mime: "image/jpeg",
                      name: "large_DSC_3488.jpg",
                      path: null,
                      size: 36.49,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3488_af905f4375",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013089/small_DSC_3488_af905f4375.jpg",
                      hash: "small_DSC_3488_af905f4375",
                      mime: "image/jpeg",
                      name: "small_DSC_3488.jpg",
                      path: null,
                      size: 14.44,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3488_af905f4375",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013089/medium_DSC_3488_af905f4375.jpg",
                      hash: "medium_DSC_3488_af905f4375",
                      mime: "image/jpeg",
                      name: "medium_DSC_3488.jpg",
                      path: null,
                      size: 24.35,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3488_af905f4375",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013087/thumbnail_DSC_3488_af905f4375.jpg",
                      hash: "thumbnail_DSC_3488_af905f4375",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3488.jpg",
                      path: null,
                      size: 3.14,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3488_af905f4375",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "343",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013111/large_DSC_3874_1b519a4d47.jpg",
                      hash: "large_DSC_3874_1b519a4d47",
                      mime: "image/jpeg",
                      name: "large_DSC_3874.jpg",
                      path: null,
                      size: 39.93,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_3874_1b519a4d47",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013111/small_DSC_3874_1b519a4d47.jpg",
                      hash: "small_DSC_3874_1b519a4d47",
                      mime: "image/jpeg",
                      name: "small_DSC_3874.jpg",
                      path: null,
                      size: 15.04,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_3874_1b519a4d47",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013111/medium_DSC_3874_1b519a4d47.jpg",
                      hash: "medium_DSC_3874_1b519a4d47",
                      mime: "image/jpeg",
                      name: "medium_DSC_3874.jpg",
                      path: null,
                      size: 26.13,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_3874_1b519a4d47",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013110/thumbnail_DSC_3874_1b519a4d47.jpg",
                      hash: "thumbnail_DSC_3874_1b519a4d47",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_3874.jpg",
                      path: null,
                      size: 3.19,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_3874_1b519a4d47",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
          {
            id: "16",
            title: "Fontanne lookbook",
            slug: "fontanne-lookbook",
            cover: {
              formats: {
                large: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014082/large_IMG_5315_8ab47f9596.jpg",
                  hash: "large_IMG_5315_8ab47f9596",
                  mime: "image/jpeg",
                  name: "large_IMG_5315.JPG",
                  path: null,
                  size: 66.67,
                  width: 667,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_IMG_5315_8ab47f9596",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014083/small_IMG_5315_8ab47f9596.jpg",
                  hash: "small_IMG_5315_8ab47f9596",
                  mime: "image/jpeg",
                  name: "small_IMG_5315.JPG",
                  path: null,
                  size: 24.32,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_IMG_5315_8ab47f9596",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014083/medium_IMG_5315_8ab47f9596.jpg",
                  hash: "medium_IMG_5315_8ab47f9596",
                  mime: "image/jpeg",
                  name: "medium_IMG_5315.JPG",
                  path: null,
                  size: 43.6,
                  width: 500,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_IMG_5315_8ab47f9596",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014074/thumbnail_IMG_5315_8ab47f9596.jpg",
                  hash: "thumbnail_IMG_5315_8ab47f9596",
                  mime: "image/jpeg",
                  name: "thumbnail_IMG_5315.JPG",
                  path: null,
                  size: 4.36,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_IMG_5315_8ab47f9596",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Fontane lookbook",
              name: "Fontane lookbook",
              images: [
                {
                  id: "355",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013728/large_IMG_5316_9a60866a55.jpg",
                      hash: "large_IMG_5316_9a60866a55",
                      mime: "image/jpeg",
                      name: "large_IMG_5316.JPG",
                      path: null,
                      size: 95.49,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5316_9a60866a55",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013729/small_IMG_5316_9a60866a55.jpg",
                      hash: "small_IMG_5316_9a60866a55",
                      mime: "image/jpeg",
                      name: "small_IMG_5316.JPG",
                      path: null,
                      size: 31.11,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5316_9a60866a55",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013729/medium_IMG_5316_9a60866a55.jpg",
                      hash: "medium_IMG_5316_9a60866a55",
                      mime: "image/jpeg",
                      name: "medium_IMG_5316.JPG",
                      path: null,
                      size: 59.78,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5316_9a60866a55",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013726/thumbnail_IMG_5316_9a60866a55.jpg",
                      hash: "thumbnail_IMG_5316_9a60866a55",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5316.JPG",
                      path: null,
                      size: 8.81,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5316_9a60866a55",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "356",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013730/large_IMG_5314_9ac8fd7df4.jpg",
                      hash: "large_IMG_5314_9ac8fd7df4",
                      mime: "image/jpeg",
                      name: "large_IMG_5314.JPG",
                      path: null,
                      size: 63.33,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5314_9ac8fd7df4",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013731/small_IMG_5314_9ac8fd7df4.jpg",
                      hash: "small_IMG_5314_9ac8fd7df4",
                      mime: "image/jpeg",
                      name: "small_IMG_5314.JPG",
                      path: null,
                      size: 22.21,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5314_9ac8fd7df4",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013730/medium_IMG_5314_9ac8fd7df4.jpg",
                      hash: "medium_IMG_5314_9ac8fd7df4",
                      mime: "image/jpeg",
                      name: "medium_IMG_5314.JPG",
                      path: null,
                      size: 40.98,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5314_9ac8fd7df4",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013728/thumbnail_IMG_5314_9ac8fd7df4.jpg",
                      hash: "thumbnail_IMG_5314_9ac8fd7df4",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5314.JPG",
                      path: null,
                      size: 7.27,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5314_9ac8fd7df4",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "357",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013732/large_IMG_5313_9de2cc5865.jpg",
                      hash: "large_IMG_5313_9de2cc5865",
                      mime: "image/jpeg",
                      name: "large_IMG_5313.JPG",
                      path: null,
                      size: 76.31,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5313_9de2cc5865",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013733/small_IMG_5313_9de2cc5865.jpg",
                      hash: "small_IMG_5313_9de2cc5865",
                      mime: "image/jpeg",
                      name: "small_IMG_5313.JPG",
                      path: null,
                      size: 25.2,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5313_9de2cc5865",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013733/medium_IMG_5313_9de2cc5865.jpg",
                      hash: "medium_IMG_5313_9de2cc5865",
                      mime: "image/jpeg",
                      name: "medium_IMG_5313.JPG",
                      path: null,
                      size: 47.75,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5313_9de2cc5865",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013730/thumbnail_IMG_5313_9de2cc5865.jpg",
                      hash: "thumbnail_IMG_5313_9de2cc5865",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5313.JPG",
                      path: null,
                      size: 7.69,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5313_9de2cc5865",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "358",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013733/large_IMG_5321_3552b5a28d.jpg",
                      hash: "large_IMG_5321_3552b5a28d",
                      mime: "image/jpeg",
                      name: "large_IMG_5321.JPG",
                      path: null,
                      size: 76.11,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5321_3552b5a28d",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013734/small_IMG_5321_3552b5a28d.jpg",
                      hash: "small_IMG_5321_3552b5a28d",
                      mime: "image/jpeg",
                      name: "small_IMG_5321.JPG",
                      path: null,
                      size: 24.42,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5321_3552b5a28d",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013733/medium_IMG_5321_3552b5a28d.jpg",
                      hash: "medium_IMG_5321_3552b5a28d",
                      mime: "image/jpeg",
                      name: "medium_IMG_5321.JPG",
                      path: null,
                      size: 47.37,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5321_3552b5a28d",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013731/thumbnail_IMG_5321_3552b5a28d.jpg",
                      hash: "thumbnail_IMG_5321_3552b5a28d",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5321.JPG",
                      path: null,
                      size: 6.89,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5321_3552b5a28d",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "359",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013857/large_IMG_5317_d2a9818899.jpg",
                      hash: "large_IMG_5317_d2a9818899",
                      mime: "image/jpeg",
                      name: "large_IMG_5317.JPG",
                      path: null,
                      size: 43.31,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5317_d2a9818899",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013858/small_IMG_5317_d2a9818899.jpg",
                      hash: "small_IMG_5317_d2a9818899",
                      mime: "image/jpeg",
                      name: "small_IMG_5317.JPG",
                      path: null,
                      size: 16,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5317_d2a9818899",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013858/medium_IMG_5317_d2a9818899.jpg",
                      hash: "medium_IMG_5317_d2a9818899",
                      mime: "image/jpeg",
                      name: "medium_IMG_5317.JPG",
                      path: null,
                      size: 28.75,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5317_d2a9818899",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013856/thumbnail_IMG_5317_d2a9818899.jpg",
                      hash: "thumbnail_IMG_5317_d2a9818899",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5317.JPG",
                      path: null,
                      size: 5.63,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5317_d2a9818899",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "360",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013868/large_IMG_5319_fc166f61c5.jpg",
                      hash: "large_IMG_5319_fc166f61c5",
                      mime: "image/jpeg",
                      name: "large_IMG_5319.JPG",
                      path: null,
                      size: 67.24,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5319_fc166f61c5",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013869/small_IMG_5319_fc166f61c5.jpg",
                      hash: "small_IMG_5319_fc166f61c5",
                      mime: "image/jpeg",
                      name: "small_IMG_5319.JPG",
                      path: null,
                      size: 22.2,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5319_fc166f61c5",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013868/medium_IMG_5319_fc166f61c5.jpg",
                      hash: "medium_IMG_5319_fc166f61c5",
                      mime: "image/jpeg",
                      name: "medium_IMG_5319.JPG",
                      path: null,
                      size: 42.36,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5319_fc166f61c5",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013866/thumbnail_IMG_5319_fc166f61c5.jpg",
                      hash: "thumbnail_IMG_5319_fc166f61c5",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5319.JPG",
                      path: null,
                      size: 6.49,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5319_fc166f61c5",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "361",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013872/large_IMG_5322_7d7d88564c.jpg",
                      hash: "large_IMG_5322_7d7d88564c",
                      mime: "image/jpeg",
                      name: "large_IMG_5322.JPG",
                      path: null,
                      size: 72.89,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_IMG_5322_7d7d88564c",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013872/small_IMG_5322_7d7d88564c.jpg",
                      hash: "small_IMG_5322_7d7d88564c",
                      mime: "image/jpeg",
                      name: "small_IMG_5322.JPG",
                      path: null,
                      size: 23.59,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_IMG_5322_7d7d88564c",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013872/medium_IMG_5322_7d7d88564c.jpg",
                      hash: "medium_IMG_5322_7d7d88564c",
                      mime: "image/jpeg",
                      name: "medium_IMG_5322.JPG",
                      path: null,
                      size: 45.74,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_IMG_5322_7d7d88564c",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607013870/thumbnail_IMG_5322_7d7d88564c.jpg",
                      hash: "thumbnail_IMG_5322_7d7d88564c",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5322.JPG",
                      path: null,
                      size: 3.41,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5322_7d7d88564c",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "362",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014078/large_IMG_5318_827a3382d1.jpg",
                      hash: "large_IMG_5318_827a3382d1",
                      mime: "image/jpeg",
                      name: "large_IMG_5318.JPG",
                      path: null,
                      size: 48.82,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5318_827a3382d1",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014082/small_IMG_5318_827a3382d1.jpg",
                      hash: "small_IMG_5318_827a3382d1",
                      mime: "image/jpeg",
                      name: "small_IMG_5318.JPG",
                      path: null,
                      size: 17.64,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5318_827a3382d1",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014082/medium_IMG_5318_827a3382d1.jpg",
                      hash: "medium_IMG_5318_827a3382d1",
                      mime: "image/jpeg",
                      name: "medium_IMG_5318.JPG",
                      path: null,
                      size: 31.85,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5318_827a3382d1",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014074/thumbnail_IMG_5318_827a3382d1.jpg",
                      hash: "thumbnail_IMG_5318_827a3382d1",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5318.JPG",
                      path: null,
                      size: 5.94,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5318_827a3382d1",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "363",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014082/large_IMG_5320_af1dca65dd.jpg",
                      hash: "large_IMG_5320_af1dca65dd",
                      mime: "image/jpeg",
                      name: "large_IMG_5320.JPG",
                      path: null,
                      size: 52.71,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5320_af1dca65dd",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014083/small_IMG_5320_af1dca65dd.jpg",
                      hash: "small_IMG_5320_af1dca65dd",
                      mime: "image/jpeg",
                      name: "small_IMG_5320.JPG",
                      path: null,
                      size: 18.62,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5320_af1dca65dd",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014083/medium_IMG_5320_af1dca65dd.jpg",
                      hash: "medium_IMG_5320_af1dca65dd",
                      mime: "image/jpeg",
                      name: "medium_IMG_5320.JPG",
                      path: null,
                      size: 33.95,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5320_af1dca65dd",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1607014075/thumbnail_IMG_5320_af1dca65dd.jpg",
                      hash: "thumbnail_IMG_5320_af1dca65dd",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5320.JPG",
                      path: null,
                      size: 5.95,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5320_af1dca65dd",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
        ],
        __typename: "Category",
      },
    },
  },
};

export default fashionMock;
