const kidAndFamilyMock = {
  result: {
    data: {
      categoryBySlug: {
        id: "2",
        name: "Kids and Families",
        slug: "kids-and-families",
        cover: {
          formats: {
            large: {
              ext: ".JPG",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714976/large_DSC_4867_4a42d270f7.jpg",
              hash: "large_DSC_4867_4a42d270f7",
              mime: "image/jpeg",
              name: "large_DSC_4867.JPG",
              path: null,
              size: 67.12,
              width: 665,
              height: 1000,
              provider_metadata: {
                public_id: "large_DSC_4867_4a42d270f7",
                resource_type: "image",
              },
            },
            small: {
              ext: ".JPG",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714977/small_DSC_4867_4a42d270f7.jpg",
              hash: "small_DSC_4867_4a42d270f7",
              mime: "image/jpeg",
              name: "small_DSC_4867.JPG",
              path: null,
              size: 22.61,
              width: 333,
              height: 500,
              provider_metadata: {
                public_id: "small_DSC_4867_4a42d270f7",
                resource_type: "image",
              },
            },
            medium: {
              ext: ".JPG",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714976/medium_DSC_4867_4a42d270f7.jpg",
              hash: "medium_DSC_4867_4a42d270f7",
              mime: "image/jpeg",
              name: "medium_DSC_4867.JPG",
              path: null,
              size: 41.49,
              width: 499,
              height: 750,
              provider_metadata: {
                public_id: "medium_DSC_4867_4a42d270f7",
                resource_type: "image",
              },
            },
            thumbnail: {
              ext: ".JPG",
              url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714975/thumbnail_DSC_4867_4a42d270f7.jpg",
              hash: "thumbnail_DSC_4867_4a42d270f7",
              mime: "image/jpeg",
              name: "thumbnail_DSC_4867.JPG",
              path: null,
              size: 4.09,
              width: 104,
              height: 156,
              provider_metadata: {
                public_id: "thumbnail_DSC_4867_4a42d270f7",
                resource_type: "image",
              },
            },
          },
          __typename: "UploadFile",
        },
        albums: [
          {
            id: "3",
            title: "Kid portrait",
            slug: "kid-portrait",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606378326/large_DSC_8409_301c1b7ca5.jpg",
                  hash: "large_DSC_8409_301c1b7ca5",
                  mime: "image/jpeg",
                  name: "large_DSC_8409.jpg",
                  path: null,
                  size: 37.32,
                  width: 665,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_DSC_8409_301c1b7ca5",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606378326/small_DSC_8409_301c1b7ca5.jpg",
                  hash: "small_DSC_8409_301c1b7ca5",
                  mime: "image/jpeg",
                  name: "small_DSC_8409.jpg",
                  path: null,
                  size: 12.88,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_DSC_8409_301c1b7ca5",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606378326/medium_DSC_8409_301c1b7ca5.jpg",
                  hash: "medium_DSC_8409_301c1b7ca5",
                  mime: "image/jpeg",
                  name: "medium_DSC_8409.jpg",
                  path: null,
                  size: 23.4,
                  width: 499,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_DSC_8409_301c1b7ca5",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606378325/thumbnail_DSC_8409_301c1b7ca5.jpg",
                  hash: "thumbnail_DSC_8409_301c1b7ca5",
                  mime: "image/jpeg",
                  name: "thumbnail_DSC_8409.jpg",
                  path: null,
                  size: 2.63,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_DSC_8409_301c1b7ca5",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Minimalist kids",
              name: "Kid portrait",
              images: [
                {
                  id: "394",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666877/large_4_5eb5a9a97f.jpg",
                      hash: "large_4_5eb5a9a97f",
                      mime: "image/jpeg",
                      name: "large_4.JPG",
                      path: null,
                      size: 42.08,
                      width: 1000,
                      height: 665,
                      provider_metadata: {
                        public_id: "large_4_5eb5a9a97f",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666877/small_4_5eb5a9a97f.jpg",
                      hash: "small_4_5eb5a9a97f",
                      mime: "image/jpeg",
                      name: "small_4.JPG",
                      path: null,
                      size: 15.03,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_4_5eb5a9a97f",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666877/medium_4_5eb5a9a97f.jpg",
                      hash: "medium_4_5eb5a9a97f",
                      mime: "image/jpeg",
                      name: "medium_4.JPG",
                      path: null,
                      size: 26.96,
                      width: 750,
                      height: 499,
                      provider_metadata: {
                        public_id: "medium_4_5eb5a9a97f",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666876/thumbnail_4_5eb5a9a97f.jpg",
                      hash: "thumbnail_4_5eb5a9a97f",
                      mime: "image/jpeg",
                      name: "thumbnail_4.JPG",
                      path: null,
                      size: 5.19,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_4_5eb5a9a97f",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "392",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666857/large_3_f091467170.jpg",
                      hash: "large_3_f091467170",
                      mime: "image/jpeg",
                      name: "large_3.JPG",
                      path: null,
                      size: 32,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_3_f091467170",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666858/small_3_f091467170.jpg",
                      hash: "small_3_f091467170",
                      mime: "image/jpeg",
                      name: "small_3.JPG",
                      path: null,
                      size: 12.1,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_3_f091467170",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666857/medium_3_f091467170.jpg",
                      hash: "medium_3_f091467170",
                      mime: "image/jpeg",
                      name: "medium_3.JPG",
                      path: null,
                      size: 21.18,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_3_f091467170",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666857/thumbnail_3_f091467170.jpg",
                      hash: "thumbnail_3_f091467170",
                      mime: "image/jpeg",
                      name: "thumbnail_3.JPG",
                      path: null,
                      size: 2.59,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_3_f091467170",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "391",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666831/large_5_b34aa8a4e4.jpg",
                      hash: "large_5_b34aa8a4e4",
                      mime: "image/jpeg",
                      name: "large_5.JPG",
                      path: null,
                      size: 26.84,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_5_b34aa8a4e4",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666832/small_5_b34aa8a4e4.jpg",
                      hash: "small_5_b34aa8a4e4",
                      mime: "image/jpeg",
                      name: "small_5.JPG",
                      path: null,
                      size: 9.49,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_5_b34aa8a4e4",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666832/medium_5_b34aa8a4e4.jpg",
                      hash: "medium_5_b34aa8a4e4",
                      mime: "image/jpeg",
                      name: "medium_5.JPG",
                      path: null,
                      size: 17.03,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_5_b34aa8a4e4",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666831/thumbnail_5_b34aa8a4e4.jpg",
                      hash: "thumbnail_5_b34aa8a4e4",
                      mime: "image/jpeg",
                      name: "thumbnail_5.JPG",
                      path: null,
                      size: 1.91,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_5_b34aa8a4e4",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "399",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666902/large_DSC_01082_e7bbefdd5a.jpg",
                      hash: "large_DSC_01082_e7bbefdd5a",
                      mime: "image/jpeg",
                      name: "large_DSC01082.jpg",
                      path: null,
                      size: 68,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01082_e7bbefdd5a",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666903/small_DSC_01082_e7bbefdd5a.jpg",
                      hash: "small_DSC_01082_e7bbefdd5a",
                      mime: "image/jpeg",
                      name: "small_DSC01082.jpg",
                      path: null,
                      size: 20.57,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01082_e7bbefdd5a",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666902/medium_DSC_01082_e7bbefdd5a.jpg",
                      hash: "medium_DSC_01082_e7bbefdd5a",
                      mime: "image/jpeg",
                      name: "medium_DSC01082.jpg",
                      path: null,
                      size: 41.31,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01082_e7bbefdd5a",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666901/thumbnail_DSC_01082_e7bbefdd5a.jpg",
                      hash: "thumbnail_DSC_01082_e7bbefdd5a",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01082.jpg",
                      path: null,
                      size: 2.78,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01082_e7bbefdd5a",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "398",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666901/large_DSC_08678_5655f416bb.jpg",
                      hash: "large_DSC_08678_5655f416bb",
                      mime: "image/jpeg",
                      name: "large_DSC08678.jpg",
                      path: null,
                      size: 47.31,
                      width: 690,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_08678_5655f416bb",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666901/small_DSC_08678_5655f416bb.jpg",
                      hash: "small_DSC_08678_5655f416bb",
                      mime: "image/jpeg",
                      name: "small_DSC08678.jpg",
                      path: null,
                      size: 17.58,
                      width: 345,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_08678_5655f416bb",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666901/medium_DSC_08678_5655f416bb.jpg",
                      hash: "medium_DSC_08678_5655f416bb",
                      mime: "image/jpeg",
                      name: "medium_DSC08678.jpg",
                      path: null,
                      size: 31.38,
                      width: 518,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_08678_5655f416bb",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666900/thumbnail_DSC_08678_5655f416bb.jpg",
                      hash: "thumbnail_DSC_08678_5655f416bb",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC08678.jpg",
                      path: null,
                      size: 3.41,
                      width: 108,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_08678_5655f416bb",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "393",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666863/large_DSC_05831_59782feb55.jpg",
                      hash: "large_DSC_05831_59782feb55",
                      mime: "image/jpeg",
                      name: "large_DSC05831.jpg",
                      path: null,
                      size: 41.78,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_DSC_05831_59782feb55",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666864/small_DSC_05831_59782feb55.jpg",
                      hash: "small_DSC_05831_59782feb55",
                      mime: "image/jpeg",
                      name: "small_DSC05831.jpg",
                      path: null,
                      size: 15.58,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_05831_59782feb55",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666864/medium_DSC_05831_59782feb55.jpg",
                      hash: "medium_DSC_05831_59782feb55",
                      mime: "image/jpeg",
                      name: "medium_DSC05831.jpg",
                      path: null,
                      size: 27.55,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_DSC_05831_59782feb55",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666862/thumbnail_DSC_05831_59782feb55.jpg",
                      hash: "thumbnail_DSC_05831_59782feb55",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC05831.jpg",
                      path: null,
                      size: 5.43,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_05831_59782feb55",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "396",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666882/large_DSC_08537_b90e5b137b.jpg",
                      hash: "large_DSC_08537_b90e5b137b",
                      mime: "image/jpeg",
                      name: "large_DSC08537.jpg",
                      path: null,
                      size: 52.43,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_08537_b90e5b137b",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666882/small_DSC_08537_b90e5b137b.jpg",
                      hash: "small_DSC_08537_b90e5b137b",
                      mime: "image/jpeg",
                      name: "small_DSC08537.jpg",
                      path: null,
                      size: 17.91,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_08537_b90e5b137b",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666882/medium_DSC_08537_b90e5b137b.jpg",
                      hash: "medium_DSC_08537_b90e5b137b",
                      mime: "image/jpeg",
                      name: "medium_DSC08537.jpg",
                      path: null,
                      size: 33.17,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_08537_b90e5b137b",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666881/thumbnail_DSC_08537_b90e5b137b.jpg",
                      hash: "thumbnail_DSC_08537_b90e5b137b",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC08537.jpg",
                      path: null,
                      size: 3.05,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_08537_b90e5b137b",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "397",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666898/large_DSC_06250_65e6169bb5.jpg",
                      hash: "large_DSC_06250_65e6169bb5",
                      mime: "image/jpeg",
                      name: "large_DSC06250.jpg",
                      path: null,
                      size: 66.26,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_DSC_06250_65e6169bb5",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666899/small_DSC_06250_65e6169bb5.jpg",
                      hash: "small_DSC_06250_65e6169bb5",
                      mime: "image/jpeg",
                      name: "small_DSC06250.jpg",
                      path: null,
                      size: 23.95,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_06250_65e6169bb5",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666898/medium_DSC_06250_65e6169bb5.jpg",
                      hash: "medium_DSC_06250_65e6169bb5",
                      mime: "image/jpeg",
                      name: "medium_DSC06250.jpg",
                      path: null,
                      size: 42.98,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_DSC_06250_65e6169bb5",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666895/thumbnail_DSC_06250_65e6169bb5.jpg",
                      hash: "thumbnail_DSC_06250_65e6169bb5",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC06250.jpg",
                      path: null,
                      size: 8,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_06250_65e6169bb5",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "395",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666880/large_DSC_00137_a2b62975b4.jpg",
                      hash: "large_DSC_00137_a2b62975b4",
                      mime: "image/jpeg",
                      name: "large_DSC00137.JPG",
                      path: null,
                      size: 40.93,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_00137_a2b62975b4",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666881/small_DSC_00137_a2b62975b4.jpg",
                      hash: "small_DSC_00137_a2b62975b4",
                      mime: "image/jpeg",
                      name: "small_DSC00137.JPG",
                      path: null,
                      size: 15.25,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_00137_a2b62975b4",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666880/medium_DSC_00137_a2b62975b4.jpg",
                      hash: "medium_DSC_00137_a2b62975b4",
                      mime: "image/jpeg",
                      name: "medium_DSC00137.JPG",
                      path: null,
                      size: 27.05,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_00137_a2b62975b4",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614666879/thumbnail_DSC_00137_a2b62975b4.jpg",
                      hash: "thumbnail_DSC_00137_a2b62975b4",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC00137.JPG",
                      path: null,
                      size: 3.07,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_00137_a2b62975b4",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
          {
            id: "6",
            title: "Kid concept",
            slug: "kid-concept",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714968/large_DSC_5109_1_d2d1b54d4e.jpg",
                  hash: "large_DSC_5109_1_d2d1b54d4e",
                  mime: "image/jpeg",
                  name: "large_DSC_5109.1.jpg",
                  path: null,
                  size: 35.03,
                  width: 665,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_DSC_5109_1_d2d1b54d4e",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714969/small_DSC_5109_1_d2d1b54d4e.jpg",
                  hash: "small_DSC_5109_1_d2d1b54d4e",
                  mime: "image/jpeg",
                  name: "small_DSC_5109.1.jpg",
                  path: null,
                  size: 12.58,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_DSC_5109_1_d2d1b54d4e",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714969/medium_DSC_5109_1_d2d1b54d4e.jpg",
                  hash: "medium_DSC_5109_1_d2d1b54d4e",
                  mime: "image/jpeg",
                  name: "medium_DSC_5109.1.jpg",
                  path: null,
                  size: 22.75,
                  width: 499,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_DSC_5109_1_d2d1b54d4e",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714968/thumbnail_DSC_5109_1_d2d1b54d4e.jpg",
                  hash: "thumbnail_DSC_5109_1_d2d1b54d4e",
                  mime: "image/jpeg",
                  name: "thumbnail_DSC_5109.1.jpg",
                  path: null,
                  size: 2.48,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_DSC_5109_1_d2d1b54d4e",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Kid concepts",
              name: "Kid concepts",
              images: [
                {
                  id: "400",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667614/large_3_871fe8ad71.jpg",
                      hash: "large_3_871fe8ad71",
                      mime: "image/jpeg",
                      name: "large_3.JPG",
                      path: null,
                      size: 36.44,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_3_871fe8ad71",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667615/small_3_871fe8ad71.jpg",
                      hash: "small_3_871fe8ad71",
                      mime: "image/jpeg",
                      name: "small_3.JPG",
                      path: null,
                      size: 12.46,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_3_871fe8ad71",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667614/medium_3_871fe8ad71.jpg",
                      hash: "medium_3_871fe8ad71",
                      mime: "image/jpeg",
                      name: "medium_3.JPG",
                      path: null,
                      size: 22.99,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_3_871fe8ad71",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667613/thumbnail_3_871fe8ad71.jpg",
                      hash: "thumbnail_3_871fe8ad71",
                      mime: "image/jpeg",
                      name: "thumbnail_3.JPG",
                      path: null,
                      size: 2.39,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_3_871fe8ad71",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "404",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/large_4_015baf0eda.jpg",
                      hash: "large_4_015baf0eda",
                      mime: "image/jpeg",
                      name: "large_4.JPG",
                      path: null,
                      size: 44.3,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_4_015baf0eda",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667618/small_4_015baf0eda.jpg",
                      hash: "small_4_015baf0eda",
                      mime: "image/jpeg",
                      name: "small_4.JPG",
                      path: null,
                      size: 16.49,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_4_015baf0eda",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/medium_4_015baf0eda.jpg",
                      hash: "medium_4_015baf0eda",
                      mime: "image/jpeg",
                      name: "medium_4.JPG",
                      path: null,
                      size: 29.24,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_4_015baf0eda",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667615/thumbnail_4_015baf0eda.jpg",
                      hash: "thumbnail_4_015baf0eda",
                      mime: "image/jpeg",
                      name: "thumbnail_4.JPG",
                      path: null,
                      size: 3.08,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_4_015baf0eda",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "403",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/large_2_416311f615.jpg",
                      hash: "large_2_416311f615",
                      mime: "image/jpeg",
                      name: "large_2.jpg",
                      path: null,
                      size: 31.45,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_2_416311f615",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667618/small_2_416311f615.jpg",
                      hash: "small_2_416311f615",
                      mime: "image/jpeg",
                      name: "small_2.jpg",
                      path: null,
                      size: 11.16,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_2_416311f615",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/medium_2_416311f615.jpg",
                      hash: "medium_2_416311f615",
                      mime: "image/jpeg",
                      name: "medium_2.jpg",
                      path: null,
                      size: 19.74,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_2_416311f615",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667615/thumbnail_2_416311f615.jpg",
                      hash: "thumbnail_2_416311f615",
                      mime: "image/jpeg",
                      name: "thumbnail_2.jpg",
                      path: null,
                      size: 2.23,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_2_416311f615",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "402",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/large_DSC_00964_a9a63a43bf.jpg",
                      hash: "large_DSC_00964_a9a63a43bf",
                      mime: "image/jpeg",
                      name: "large_DSC00964.jpg",
                      path: null,
                      size: 55.45,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_00964_a9a63a43bf",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667618/small_DSC_00964_a9a63a43bf.jpg",
                      hash: "small_DSC_00964_a9a63a43bf",
                      mime: "image/jpeg",
                      name: "small_DSC00964.jpg",
                      path: null,
                      size: 18.79,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_00964_a9a63a43bf",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/medium_DSC_00964_a9a63a43bf.jpg",
                      hash: "medium_DSC_00964_a9a63a43bf",
                      mime: "image/jpeg",
                      name: "medium_DSC00964.jpg",
                      path: null,
                      size: 34.73,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_00964_a9a63a43bf",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667615/thumbnail_DSC_00964_a9a63a43bf.jpg",
                      hash: "thumbnail_DSC_00964_a9a63a43bf",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC00964.jpg",
                      path: null,
                      size: 3.18,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_00964_a9a63a43bf",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "401",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667616/large_6_50b4b6c996.jpg",
                      hash: "large_6_50b4b6c996",
                      mime: "image/jpeg",
                      name: "large_6'.JPG",
                      path: null,
                      size: 53.94,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_6_50b4b6c996",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/small_6_50b4b6c996.jpg",
                      hash: "small_6_50b4b6c996",
                      mime: "image/jpeg",
                      name: "small_6'.JPG",
                      path: null,
                      size: 21.51,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_6_50b4b6c996",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667616/medium_6_50b4b6c996.jpg",
                      hash: "medium_6_50b4b6c996",
                      mime: "image/jpeg",
                      name: "medium_6'.JPG",
                      path: null,
                      size: 36.6,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_6_50b4b6c996",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667614/thumbnail_6_50b4b6c996.jpg",
                      hash: "thumbnail_6_50b4b6c996",
                      mime: "image/jpeg",
                      name: "thumbnail_6'.JPG",
                      path: null,
                      size: 4.66,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_6_50b4b6c996",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "406",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667624/large_DSC_01118_c4531674a5.jpg",
                      hash: "large_DSC_01118_c4531674a5",
                      mime: "image/jpeg",
                      name: "large_DSC01118.jpg",
                      path: null,
                      size: 32.49,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01118_c4531674a5",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667625/small_DSC_01118_c4531674a5.jpg",
                      hash: "small_DSC_01118_c4531674a5",
                      mime: "image/jpeg",
                      name: "small_DSC01118.jpg",
                      path: null,
                      size: 11.9,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01118_c4531674a5",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667625/medium_DSC_01118_c4531674a5.jpg",
                      hash: "medium_DSC_01118_c4531674a5",
                      mime: "image/jpeg",
                      name: "medium_DSC01118.jpg",
                      path: null,
                      size: 21.12,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01118_c4531674a5",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667623/thumbnail_DSC_01118_c4531674a5.jpg",
                      hash: "thumbnail_DSC_01118_c4531674a5",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01118.jpg",
                      path: null,
                      size: 2.29,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01118_c4531674a5",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "407",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667624/large_DSC_04809_f08b81f9d7.jpg",
                      hash: "large_DSC_04809_f08b81f9d7",
                      mime: "image/jpeg",
                      name: "large_DSC04809.jpg",
                      path: null,
                      size: 32.03,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_04809_f08b81f9d7",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667625/small_DSC_04809_f08b81f9d7.jpg",
                      hash: "small_DSC_04809_f08b81f9d7",
                      mime: "image/jpeg",
                      name: "small_DSC04809.jpg",
                      path: null,
                      size: 12.09,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_04809_f08b81f9d7",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667625/medium_DSC_04809_f08b81f9d7.jpg",
                      hash: "medium_DSC_04809_f08b81f9d7",
                      mime: "image/jpeg",
                      name: "medium_DSC04809.jpg",
                      path: null,
                      size: 20.92,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_04809_f08b81f9d7",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667624/thumbnail_DSC_04809_f08b81f9d7.jpg",
                      hash: "thumbnail_DSC_04809_f08b81f9d7",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC04809.jpg",
                      path: null,
                      size: 2.64,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_04809_f08b81f9d7",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "408",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667642/large_DSC_04905_e421c51d61.jpg",
                      hash: "large_DSC_04905_e421c51d61",
                      mime: "image/jpeg",
                      name: "large_DSC04905.jpg",
                      path: null,
                      size: 79.54,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_04905_e421c51d61",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667643/small_DSC_04905_e421c51d61.jpg",
                      hash: "small_DSC_04905_e421c51d61",
                      mime: "image/jpeg",
                      name: "small_DSC04905.jpg",
                      path: null,
                      size: 30.3,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_04905_e421c51d61",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667643/medium_DSC_04905_e421c51d61.jpg",
                      hash: "medium_DSC_04905_e421c51d61",
                      mime: "image/jpeg",
                      name: "medium_DSC04905.jpg",
                      path: null,
                      size: 52.97,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_04905_e421c51d61",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667641/thumbnail_DSC_04905_e421c51d61.jpg",
                      hash: "thumbnail_DSC_04905_e421c51d61",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC04905.jpg",
                      path: null,
                      size: 5.8,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_04905_e421c51d61",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "405",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667618/large_8_5103c21ea7.jpg",
                      hash: "large_8_5103c21ea7",
                      mime: "image/jpeg",
                      name: "large_8'.jpg",
                      path: null,
                      size: 34.52,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_8_5103c21ea7",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667619/small_8_5103c21ea7.jpg",
                      hash: "small_8_5103c21ea7",
                      mime: "image/jpeg",
                      name: "small_8'.jpg",
                      path: null,
                      size: 12.15,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_8_5103c21ea7",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667618/medium_8_5103c21ea7.jpg",
                      hash: "medium_8_5103c21ea7",
                      mime: "image/jpeg",
                      name: "medium_8'.jpg",
                      path: null,
                      size: 21.81,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_8_5103c21ea7",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614667617/thumbnail_8_5103c21ea7.jpg",
                      hash: "thumbnail_8_5103c21ea7",
                      mime: "image/jpeg",
                      name: "thumbnail_8'.jpg",
                      path: null,
                      size: 2.42,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_8_5103c21ea7",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
          {
            id: "7",
            title: "Kids lifestyle",
            slug: "kids-lifestyle",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606720576/large_VQDF_9353_f85323a1fd.jpg",
                  hash: "large_VQDF_9353_f85323a1fd",
                  mime: "image/jpeg",
                  name: "large_VQDF9353.jpg",
                  path: null,
                  size: 110.28,
                  width: 749,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_VQDF_9353_f85323a1fd",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606720576/small_VQDF_9353_f85323a1fd.jpg",
                  hash: "small_VQDF_9353_f85323a1fd",
                  mime: "image/jpeg",
                  name: "small_VQDF9353.jpg",
                  path: null,
                  size: 36.62,
                  width: 375,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_VQDF_9353_f85323a1fd",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606720576/medium_VQDF_9353_f85323a1fd.jpg",
                  hash: "medium_VQDF_9353_f85323a1fd",
                  mime: "image/jpeg",
                  name: "medium_VQDF9353.jpg",
                  path: null,
                  size: 69.72,
                  width: 562,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_VQDF_9353_f85323a1fd",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606720575/thumbnail_VQDF_9353_f85323a1fd.jpg",
                  hash: "thumbnail_VQDF_9353_f85323a1fd",
                  mime: "image/jpeg",
                  name: "thumbnail_VQDF9353.jpg",
                  path: null,
                  size: 5.84,
                  width: 117,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_VQDF_9353_f85323a1fd",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Kids lifestyle",
              name: "Kids lifestyle",
              images: [
                {
                  id: "152",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/large_1_d51e8666b4.jpg",
                      hash: "large_1_d51e8666b4",
                      mime: "image/jpeg",
                      name: "large_1.jpg",
                      path: null,
                      size: 164.44,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_1_d51e8666b4",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894527/small_1_d51e8666b4.jpg",
                      hash: "small_1_d51e8666b4",
                      mime: "image/jpeg",
                      name: "small_1.jpg",
                      path: null,
                      size: 47.92,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_1_d51e8666b4",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/medium_1_d51e8666b4.jpg",
                      hash: "medium_1_d51e8666b4",
                      mime: "image/jpeg",
                      name: "medium_1.jpg",
                      path: null,
                      size: 98.17,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_1_d51e8666b4",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894520/thumbnail_1_d51e8666b4.jpg",
                      hash: "thumbnail_1_d51e8666b4",
                      mime: "image/jpeg",
                      name: "thumbnail_1.jpg",
                      path: null,
                      size: 11.37,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_1_d51e8666b4",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "149",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894524/large_2_14c4d6208a.jpg",
                      hash: "large_2_14c4d6208a",
                      mime: "image/jpeg",
                      name: "large_2.JPG",
                      path: null,
                      size: 170.53,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_2_14c4d6208a",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/small_2_14c4d6208a.jpg",
                      hash: "small_2_14c4d6208a",
                      mime: "image/jpeg",
                      name: "small_2.JPG",
                      path: null,
                      size: 52.39,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_2_14c4d6208a",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894524/medium_2_14c4d6208a.jpg",
                      hash: "medium_2_14c4d6208a",
                      mime: "image/jpeg",
                      name: "medium_2.JPG",
                      path: null,
                      size: 104.51,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_2_14c4d6208a",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894519/thumbnail_2_14c4d6208a.jpg",
                      hash: "thumbnail_2_14c4d6208a",
                      mime: "image/jpeg",
                      name: "thumbnail_2.JPG",
                      path: null,
                      size: 12.84,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_2_14c4d6208a",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "150",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894524/large_3_2f7820ccd3.jpg",
                      hash: "large_3_2f7820ccd3",
                      mime: "image/jpeg",
                      name: "large_3.JPG",
                      path: null,
                      size: 141.45,
                      width: 1000,
                      height: 666,
                      provider_metadata: {
                        public_id: "large_3_2f7820ccd3",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/small_3_2f7820ccd3.jpg",
                      hash: "small_3_2f7820ccd3",
                      mime: "image/jpeg",
                      name: "small_3.JPG",
                      path: null,
                      size: 43.95,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_3_2f7820ccd3",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/medium_3_2f7820ccd3.jpg",
                      hash: "medium_3_2f7820ccd3",
                      mime: "image/jpeg",
                      name: "medium_3.JPG",
                      path: null,
                      size: 88.47,
                      width: 750,
                      height: 499,
                      provider_metadata: {
                        public_id: "medium_3_2f7820ccd3",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894518/thumbnail_3_2f7820ccd3.jpg",
                      hash: "thumbnail_3_2f7820ccd3",
                      mime: "image/jpeg",
                      name: "thumbnail_3.JPG",
                      path: null,
                      size: 11.97,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_3_2f7820ccd3",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "154",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894527/large_4_b5f158369d.jpg",
                      hash: "large_4_b5f158369d",
                      mime: "image/jpeg",
                      name: "large_4.JPG",
                      path: null,
                      size: 134.27,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_4_b5f158369d",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894528/small_4_b5f158369d.jpg",
                      hash: "small_4_b5f158369d",
                      mime: "image/jpeg",
                      name: "small_4.JPG",
                      path: null,
                      size: 46.68,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_4_b5f158369d",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894528/medium_4_b5f158369d.jpg",
                      hash: "medium_4_b5f158369d",
                      mime: "image/jpeg",
                      name: "medium_4.JPG",
                      path: null,
                      size: 86.48,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_4_b5f158369d",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/thumbnail_4_b5f158369d.jpg",
                      hash: "thumbnail_4_b5f158369d",
                      mime: "image/jpeg",
                      name: "thumbnail_4.JPG",
                      path: null,
                      size: 13.57,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_4_b5f158369d",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "151",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894524/large_5_2fbb6fce1c.jpg",
                      hash: "large_5_2fbb6fce1c",
                      mime: "image/jpeg",
                      name: "large_5.JPG",
                      path: null,
                      size: 147.13,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_5_2fbb6fce1c",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/small_5_2fbb6fce1c.jpg",
                      hash: "small_5_2fbb6fce1c",
                      mime: "image/jpeg",
                      name: "small_5.JPG",
                      path: null,
                      size: 46.86,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_5_2fbb6fce1c",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/medium_5_2fbb6fce1c.jpg",
                      hash: "medium_5_2fbb6fce1c",
                      mime: "image/jpeg",
                      name: "medium_5.JPG",
                      path: null,
                      size: 91.38,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_5_2fbb6fce1c",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894519/thumbnail_5_2fbb6fce1c.jpg",
                      hash: "thumbnail_5_2fbb6fce1c",
                      mime: "image/jpeg",
                      name: "thumbnail_5.JPG",
                      path: null,
                      size: 5.77,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_5_2fbb6fce1c",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "153",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894527/large_6_6fbe1ec912.jpg",
                      hash: "large_6_6fbe1ec912",
                      mime: "image/jpeg",
                      name: "large_6.jpg",
                      path: null,
                      size: 171.88,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_6_6fbe1ec912",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894528/small_6_6fbe1ec912.jpg",
                      hash: "small_6_6fbe1ec912",
                      mime: "image/jpeg",
                      name: "small_6.jpg",
                      path: null,
                      size: 51.05,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_6_6fbe1ec912",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894527/medium_6_6fbe1ec912.jpg",
                      hash: "medium_6_6fbe1ec912",
                      mime: "image/jpeg",
                      name: "medium_6.jpg",
                      path: null,
                      size: 106.33,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_6_6fbe1ec912",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894525/thumbnail_6_6fbe1ec912.jpg",
                      hash: "thumbnail_6_6fbe1ec912",
                      mime: "image/jpeg",
                      name: "thumbnail_6.jpg",
                      path: null,
                      size: 6.16,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_6_6fbe1ec912",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "157",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894545/large_7_f43f46c0e5.jpg",
                      hash: "large_7_f43f46c0e5",
                      mime: "image/jpeg",
                      name: "large_7.jpg",
                      path: null,
                      size: 134.98,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_7_f43f46c0e5",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894545/small_7_f43f46c0e5.jpg",
                      hash: "small_7_f43f46c0e5",
                      mime: "image/jpeg",
                      name: "small_7.jpg",
                      path: null,
                      size: 41.85,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_7_f43f46c0e5",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894545/medium_7_f43f46c0e5.jpg",
                      hash: "medium_7_f43f46c0e5",
                      mime: "image/jpeg",
                      name: "medium_7.jpg",
                      path: null,
                      size: 83.03,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_7_f43f46c0e5",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894543/thumbnail_7_f43f46c0e5.jpg",
                      hash: "thumbnail_7_f43f46c0e5",
                      mime: "image/jpeg",
                      name: "thumbnail_7.jpg",
                      path: null,
                      size: 10.93,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_7_f43f46c0e5",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "155",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894541/large_8_07e650779c.jpg",
                      hash: "large_8_07e650779c",
                      mime: "image/jpeg",
                      name: "large_8.jpg",
                      path: null,
                      size: 146.38,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_8_07e650779c",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894542/small_8_07e650779c.jpg",
                      hash: "small_8_07e650779c",
                      mime: "image/jpeg",
                      name: "small_8.jpg",
                      path: null,
                      size: 43.13,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_8_07e650779c",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894541/medium_8_07e650779c.jpg",
                      hash: "medium_8_07e650779c",
                      mime: "image/jpeg",
                      name: "medium_8.jpg",
                      path: null,
                      size: 89.72,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_8_07e650779c",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894539/thumbnail_8_07e650779c.jpg",
                      hash: "thumbnail_8_07e650779c",
                      mime: "image/jpeg",
                      name: "thumbnail_8.jpg",
                      path: null,
                      size: 11.17,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_8_07e650779c",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "156",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894544/large_9_41dbaab65e.jpg",
                      hash: "large_9_41dbaab65e",
                      mime: "image/jpeg",
                      name: "large_9.JPG",
                      path: null,
                      size: 153.74,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_9_41dbaab65e",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894544/small_9_41dbaab65e.jpg",
                      hash: "small_9_41dbaab65e",
                      mime: "image/jpeg",
                      name: "small_9.JPG",
                      path: null,
                      size: 49.59,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_9_41dbaab65e",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894544/medium_9_41dbaab65e.jpg",
                      hash: "medium_9_41dbaab65e",
                      mime: "image/jpeg",
                      name: "medium_9.JPG",
                      path: null,
                      size: 97.59,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_9_41dbaab65e",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894541/thumbnail_9_41dbaab65e.jpg",
                      hash: "thumbnail_9_41dbaab65e",
                      mime: "image/jpeg",
                      name: "thumbnail_9.JPG",
                      path: null,
                      size: 12.6,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_9_41dbaab65e",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "409",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668064/large_IMG_5284_d98215d615.jpg",
                      hash: "large_IMG_5284_d98215d615",
                      mime: "image/jpeg",
                      name: "large_IMG_5284.jpg",
                      path: null,
                      size: 51.43,
                      width: 1000,
                      height: 665,
                      provider_metadata: {
                        public_id: "large_IMG_5284_d98215d615",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668066/small_IMG_5284_d98215d615.jpg",
                      hash: "small_IMG_5284_d98215d615",
                      mime: "image/jpeg",
                      name: "small_IMG_5284.jpg",
                      path: null,
                      size: 17.37,
                      width: 500,
                      height: 332,
                      provider_metadata: {
                        public_id: "small_IMG_5284_d98215d615",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668065/medium_IMG_5284_d98215d615.jpg",
                      hash: "medium_IMG_5284_d98215d615",
                      mime: "image/jpeg",
                      name: "medium_IMG_5284.jpg",
                      path: null,
                      size: 31.93,
                      width: 750,
                      height: 499,
                      provider_metadata: {
                        public_id: "medium_IMG_5284_d98215d615",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668064/thumbnail_IMG_5284_d98215d615.jpg",
                      hash: "thumbnail_IMG_5284_d98215d615",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5284.jpg",
                      path: null,
                      size: 5.63,
                      width: 235,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5284_d98215d615",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "410",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668069/large_DSC_06015_5ca58b5655.jpg",
                      hash: "large_DSC_06015_5ca58b5655",
                      mime: "image/jpeg",
                      name: "large_DSC06015.JPG",
                      path: null,
                      size: 54.33,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_DSC_06015_5ca58b5655",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668072/small_DSC_06015_5ca58b5655.jpg",
                      hash: "small_DSC_06015_5ca58b5655",
                      mime: "image/jpeg",
                      name: "small_DSC06015.JPG",
                      path: null,
                      size: 19.24,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_06015_5ca58b5655",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668069/medium_DSC_06015_5ca58b5655.jpg",
                      hash: "medium_DSC_06015_5ca58b5655",
                      mime: "image/jpeg",
                      name: "medium_DSC06015.JPG",
                      path: null,
                      size: 35.15,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_DSC_06015_5ca58b5655",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668068/thumbnail_DSC_06015_5ca58b5655.jpg",
                      hash: "thumbnail_DSC_06015_5ca58b5655",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC06015.JPG",
                      path: null,
                      size: 6.22,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_06015_5ca58b5655",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "411",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668077/large_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231.jpg",
                      hash: "large_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                      mime: "image/jpeg",
                      name: "large_9CB1DCA6-BA52-4004-A061-B7EBBD86DA54.JPG",
                      path: null,
                      size: 136.05,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id:
                          "large_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668078/small_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231.jpg",
                      hash: "small_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                      mime: "image/jpeg",
                      name: "small_9CB1DCA6-BA52-4004-A061-B7EBBD86DA54.JPG",
                      path: null,
                      size: 46,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id:
                          "small_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668077/medium_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231.jpg",
                      hash: "medium_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                      mime: "image/jpeg",
                      name: "medium_9CB1DCA6-BA52-4004-A061-B7EBBD86DA54.JPG",
                      path: null,
                      size: 86.24,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id:
                          "medium_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614668075/thumbnail_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231.jpg",
                      hash: "thumbnail_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                      mime: "image/jpeg",
                      name: "thumbnail_9CB1DCA6-BA52-4004-A061-B7EBBD86DA54.JPG",
                      path: null,
                      size: 13.2,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id:
                          "thumbnail_9_CB_1_DCA_6_BA_52_4004_A061_B7_EBBD_86_DA_54_0ba0ccd231",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
          {
            id: "8",
            title: "Family portrait",
            slug: "family-portrait",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669344/large_DSC_06179_post_1655e2089b.jpg",
                  hash: "large_DSC_06179_post_1655e2089b",
                  mime: "image/jpeg",
                  name: "large_DSC06179 post.jpg",
                  path: null,
                  size: 59.46,
                  width: 667,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_DSC_06179_post_1655e2089b",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669345/small_DSC_06179_post_1655e2089b.jpg",
                  hash: "small_DSC_06179_post_1655e2089b",
                  mime: "image/jpeg",
                  name: "small_DSC06179 post.jpg",
                  path: null,
                  size: 19.88,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_DSC_06179_post_1655e2089b",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669345/medium_DSC_06179_post_1655e2089b.jpg",
                  hash: "medium_DSC_06179_post_1655e2089b",
                  mime: "image/jpeg",
                  name: "medium_DSC06179 post.jpg",
                  path: null,
                  size: 36.83,
                  width: 500,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_DSC_06179_post_1655e2089b",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669344/thumbnail_DSC_06179_post_1655e2089b.jpg",
                  hash: "thumbnail_DSC_06179_post_1655e2089b",
                  mime: "image/jpeg",
                  name: "thumbnail_DSC06179 post.jpg",
                  path: null,
                  size: 3.51,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_DSC_06179_post_1655e2089b",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Family portrait",
              name: "Family portrait",
              images: [
                {
                  id: "420",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669237/large_DSC_00301_480b1f3219.jpg",
                      hash: "large_DSC_00301_480b1f3219",
                      mime: "image/jpeg",
                      name: "large_DSC00301.jpg",
                      path: null,
                      size: 45.43,
                      width: 1000,
                      height: 672,
                      provider_metadata: {
                        public_id: "large_DSC_00301_480b1f3219",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669238/small_DSC_00301_480b1f3219.jpg",
                      hash: "small_DSC_00301_480b1f3219",
                      mime: "image/jpeg",
                      name: "small_DSC00301.jpg",
                      path: null,
                      size: 14.91,
                      width: 500,
                      height: 336,
                      provider_metadata: {
                        public_id: "small_DSC_00301_480b1f3219",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669238/medium_DSC_00301_480b1f3219.jpg",
                      hash: "medium_DSC_00301_480b1f3219",
                      mime: "image/jpeg",
                      name: "medium_DSC00301.jpg",
                      path: null,
                      size: 28.73,
                      width: 750,
                      height: 504,
                      provider_metadata: {
                        public_id: "medium_DSC_00301_480b1f3219",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669237/thumbnail_DSC_00301_480b1f3219.jpg",
                      hash: "thumbnail_DSC_00301_480b1f3219",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC00301.jpg",
                      path: null,
                      size: 4.72,
                      width: 232,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_00301_480b1f3219",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "419",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669236/large_DSC_00475bw_b6ddb20043.jpg",
                      hash: "large_DSC_00475bw_b6ddb20043",
                      mime: "image/jpeg",
                      name: "large_DSC00475bw.jpg",
                      path: null,
                      size: 60.97,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_00475bw_b6ddb20043",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669236/small_DSC_00475bw_b6ddb20043.jpg",
                      hash: "small_DSC_00475bw_b6ddb20043",
                      mime: "image/jpeg",
                      name: "small_DSC00475bw.jpg",
                      path: null,
                      size: 17.72,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_00475bw_b6ddb20043",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669236/medium_DSC_00475bw_b6ddb20043.jpg",
                      hash: "medium_DSC_00475bw_b6ddb20043",
                      mime: "image/jpeg",
                      name: "medium_DSC00475bw.jpg",
                      path: null,
                      size: 35.75,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_00475bw_b6ddb20043",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669235/thumbnail_DSC_00475bw_b6ddb20043.jpg",
                      hash: "thumbnail_DSC_00475bw_b6ddb20043",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC00475bw.jpg",
                      path: null,
                      size: 3.09,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_00475bw_b6ddb20043",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "414",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669217/large_DSC_05364bw_ceac98d829.jpg",
                      hash: "large_DSC_05364bw_ceac98d829",
                      mime: "image/jpeg",
                      name: "large_DSC05364bw.jpg",
                      path: null,
                      size: 40.28,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_DSC_05364bw_ceac98d829",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669218/small_DSC_05364bw_ceac98d829.jpg",
                      hash: "small_DSC_05364bw_ceac98d829",
                      mime: "image/jpeg",
                      name: "small_DSC05364bw.jpg",
                      path: null,
                      size: 11.35,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_05364bw_ceac98d829",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669218/medium_DSC_05364bw_ceac98d829.jpg",
                      hash: "medium_DSC_05364bw_ceac98d829",
                      mime: "image/jpeg",
                      name: "medium_DSC05364bw.jpg",
                      path: null,
                      size: 22.66,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_DSC_05364bw_ceac98d829",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669216/thumbnail_DSC_05364bw_ceac98d829.jpg",
                      hash: "thumbnail_DSC_05364bw_ceac98d829",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC05364bw.jpg",
                      path: null,
                      size: 3.58,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_05364bw_ceac98d829",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "421",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669238/large_3_575b99260d.jpg",
                      hash: "large_3_575b99260d",
                      mime: "image/jpeg",
                      name: "large_3.JPG",
                      path: null,
                      size: 40.99,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_3_575b99260d",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669239/small_3_575b99260d.jpg",
                      hash: "small_3_575b99260d",
                      mime: "image/jpeg",
                      name: "small_3.JPG",
                      path: null,
                      size: 14.59,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_3_575b99260d",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669238/medium_3_575b99260d.jpg",
                      hash: "medium_3_575b99260d",
                      mime: "image/jpeg",
                      name: "medium_3.JPG",
                      path: null,
                      size: 26.35,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_3_575b99260d",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669237/thumbnail_3_575b99260d.jpg",
                      hash: "thumbnail_3_575b99260d",
                      mime: "image/jpeg",
                      name: "thumbnail_3.JPG",
                      path: null,
                      size: 2.86,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_3_575b99260d",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "416",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669231/large_5_0565f039ee.jpg",
                      hash: "large_5_0565f039ee",
                      mime: "image/jpeg",
                      name: "large_5.JPG",
                      path: null,
                      size: 49.48,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_5_0565f039ee",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669232/small_5_0565f039ee.jpg",
                      hash: "small_5_0565f039ee",
                      mime: "image/jpeg",
                      name: "small_5.JPG",
                      path: null,
                      size: 17.95,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_5_0565f039ee",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669232/medium_5_0565f039ee.jpg",
                      hash: "medium_5_0565f039ee",
                      mime: "image/jpeg",
                      name: "medium_5.JPG",
                      path: null,
                      size: 32.22,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_5_0565f039ee",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669231/thumbnail_5_0565f039ee.jpg",
                      hash: "thumbnail_5_0565f039ee",
                      mime: "image/jpeg",
                      name: "thumbnail_5.JPG",
                      path: null,
                      size: 3.24,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_5_0565f039ee",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "417",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669233/large_DSC_01145_4c14568561.jpg",
                      hash: "large_DSC_01145_4c14568561",
                      mime: "image/jpeg",
                      name: "large_DSC01145.JPG",
                      path: null,
                      size: 51.79,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01145_4c14568561",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669234/small_DSC_01145_4c14568561.jpg",
                      hash: "small_DSC_01145_4c14568561",
                      mime: "image/jpeg",
                      name: "small_DSC01145.JPG",
                      path: null,
                      size: 16.76,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01145_4c14568561",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669233/medium_DSC_01145_4c14568561.jpg",
                      hash: "medium_DSC_01145_4c14568561",
                      mime: "image/jpeg",
                      name: "medium_DSC01145.JPG",
                      path: null,
                      size: 31.47,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01145_4c14568561",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669232/thumbnail_DSC_01145_4c14568561.jpg",
                      hash: "thumbnail_DSC_01145_4c14568561",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01145.JPG",
                      path: null,
                      size: 3.12,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01145_4c14568561",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "418",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669234/large_6_453d11471d.jpg",
                      hash: "large_6_453d11471d",
                      mime: "image/jpeg",
                      name: "large_6.JPG",
                      path: null,
                      size: 67.12,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_6_453d11471d",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669234/small_6_453d11471d.jpg",
                      hash: "small_6_453d11471d",
                      mime: "image/jpeg",
                      name: "small_6.JPG",
                      path: null,
                      size: 22.61,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_6_453d11471d",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669234/medium_6_453d11471d.jpg",
                      hash: "medium_6_453d11471d",
                      mime: "image/jpeg",
                      name: "medium_6.JPG",
                      path: null,
                      size: 41.49,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_6_453d11471d",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669233/thumbnail_6_453d11471d.jpg",
                      hash: "thumbnail_6_453d11471d",
                      mime: "image/jpeg",
                      name: "thumbnail_6.JPG",
                      path: null,
                      size: 4.09,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_6_453d11471d",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "415",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669218/large_DSC_08761_263897ae71.jpg",
                      hash: "large_DSC_08761_263897ae71",
                      mime: "image/jpeg",
                      name: "large_DSC08761.jpg",
                      path: null,
                      size: 64.23,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_08761_263897ae71",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669218/small_DSC_08761_263897ae71.jpg",
                      hash: "small_DSC_08761_263897ae71",
                      mime: "image/jpeg",
                      name: "small_DSC08761.jpg",
                      path: null,
                      size: 21.81,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_08761_263897ae71",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669218/medium_DSC_08761_263897ae71.jpg",
                      hash: "medium_DSC_08761_263897ae71",
                      mime: "image/jpeg",
                      name: "medium_DSC08761.jpg",
                      path: null,
                      size: 40.9,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_08761_263897ae71",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669216/thumbnail_DSC_08761_263897ae71.jpg",
                      hash: "thumbnail_DSC_08761_263897ae71",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC08761.jpg",
                      path: null,
                      size: 3.65,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_08761_263897ae71",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "413",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669217/large_DSC_07424bw_87b204cbf0.jpg",
                      hash: "large_DSC_07424bw_87b204cbf0",
                      mime: "image/jpeg",
                      name: "large_DSC07424bw.jpg",
                      path: null,
                      size: 61.29,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_07424bw_87b204cbf0",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669218/small_DSC_07424bw_87b204cbf0.jpg",
                      hash: "small_DSC_07424bw_87b204cbf0",
                      mime: "image/jpeg",
                      name: "small_DSC07424bw.jpg",
                      path: null,
                      size: 20.42,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_07424bw_87b204cbf0",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669217/medium_DSC_07424bw_87b204cbf0.jpg",
                      hash: "medium_DSC_07424bw_87b204cbf0",
                      mime: "image/jpeg",
                      name: "medium_DSC07424bw.jpg",
                      path: null,
                      size: 37.79,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_07424bw_87b204cbf0",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1614669216/thumbnail_DSC_07424bw_87b204cbf0.jpg",
                      hash: "thumbnail_DSC_07424bw_87b204cbf0",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC07424bw.jpg",
                      path: null,
                      size: 3.52,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_07424bw_87b204cbf0",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
          {
            id: "9",
            title: "Family lifestyle",
            slug: "family-lifestyle",
            cover: {
              formats: {
                large: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715075/large_7_057813fd71.jpg",
                  hash: "large_7_057813fd71",
                  mime: "image/jpeg",
                  name: "large_7.JPG",
                  path: null,
                  size: 136.42,
                  width: 667,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_7_057813fd71",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715075/small_7_057813fd71.jpg",
                  hash: "small_7_057813fd71",
                  mime: "image/jpeg",
                  name: "small_7.JPG",
                  path: null,
                  size: 38.67,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_7_057813fd71",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715075/medium_7_057813fd71.jpg",
                  hash: "medium_7_057813fd71",
                  mime: "image/jpeg",
                  name: "medium_7.JPG",
                  path: null,
                  size: 79.46,
                  width: 500,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_7_057813fd71",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".JPG",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715074/thumbnail_7_057813fd71.jpg",
                  hash: "thumbnail_7_057813fd71",
                  mime: "image/jpeg",
                  name: "thumbnail_7.JPG",
                  path: null,
                  size: 5,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_7_057813fd71",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Family lifestyle",
              name: "Family lifestyle",
              images: [
                {
                  id: "93",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715067/large_1_e0278ff3c3.jpg",
                      hash: "large_1_e0278ff3c3",
                      mime: "image/jpeg",
                      name: "large_1.JPG",
                      path: null,
                      size: 68.67,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_1_e0278ff3c3",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715067/small_1_e0278ff3c3.jpg",
                      hash: "small_1_e0278ff3c3",
                      mime: "image/jpeg",
                      name: "small_1.JPG",
                      path: null,
                      size: 23.55,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_1_e0278ff3c3",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715067/medium_1_e0278ff3c3.jpg",
                      hash: "medium_1_e0278ff3c3",
                      mime: "image/jpeg",
                      name: "medium_1.JPG",
                      path: null,
                      size: 43.93,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_1_e0278ff3c3",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715066/thumbnail_1_e0278ff3c3.jpg",
                      hash: "thumbnail_1_e0278ff3c3",
                      mime: "image/jpeg",
                      name: "thumbnail_1.JPG",
                      path: null,
                      size: 3.77,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_1_e0278ff3c3",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "92",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715067/large_4_7e5523bda1.jpg",
                      hash: "large_4_7e5523bda1",
                      mime: "image/jpeg",
                      name: "large_4.JPG",
                      path: null,
                      size: 110.49,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_4_7e5523bda1",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715067/small_4_7e5523bda1.jpg",
                      hash: "small_4_7e5523bda1",
                      mime: "image/jpeg",
                      name: "small_4.JPG",
                      path: null,
                      size: 33.19,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_4_7e5523bda1",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715067/medium_4_7e5523bda1.jpg",
                      hash: "medium_4_7e5523bda1",
                      mime: "image/jpeg",
                      name: "medium_4.JPG",
                      path: null,
                      size: 66.79,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_4_7e5523bda1",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606715066/thumbnail_4_7e5523bda1.jpg",
                      hash: "thumbnail_4_7e5523bda1",
                      mime: "image/jpeg",
                      name: "thumbnail_4.JPG",
                      path: null,
                      size: 9.01,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_4_7e5523bda1",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "129",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892035/large_DSC_01962_c3bc7faac7.jpg",
                      hash: "large_DSC_01962_c3bc7faac7",
                      mime: "image/jpeg",
                      name: "large_DSC01962.JPG",
                      path: null,
                      size: 80.58,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01962_c3bc7faac7",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892036/small_DSC_01962_c3bc7faac7.jpg",
                      hash: "small_DSC_01962_c3bc7faac7",
                      mime: "image/jpeg",
                      name: "small_DSC01962.JPG",
                      path: null,
                      size: 26.75,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01962_c3bc7faac7",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892036/medium_DSC_01962_c3bc7faac7.jpg",
                      hash: "medium_DSC_01962_c3bc7faac7",
                      mime: "image/jpeg",
                      name: "medium_DSC01962.JPG",
                      path: null,
                      size: 51.04,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01962_c3bc7faac7",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892034/thumbnail_DSC_01962_c3bc7faac7.jpg",
                      hash: "thumbnail_DSC_01962_c3bc7faac7",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01962.JPG",
                      path: null,
                      size: 3.95,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01962_c3bc7faac7",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "128",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892035/large_DSC_01971_b1cd2b519a.jpg",
                      hash: "large_DSC_01971_b1cd2b519a",
                      mime: "image/jpeg",
                      name: "large_DSC01971.JPG",
                      path: null,
                      size: 70.11,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01971_b1cd2b519a",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892036/small_DSC_01971_b1cd2b519a.jpg",
                      hash: "small_DSC_01971_b1cd2b519a",
                      mime: "image/jpeg",
                      name: "small_DSC01971.JPG",
                      path: null,
                      size: 24.36,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01971_b1cd2b519a",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892035/medium_DSC_01971_b1cd2b519a.jpg",
                      hash: "medium_DSC_01971_b1cd2b519a",
                      mime: "image/jpeg",
                      name: "medium_DSC01971.JPG",
                      path: null,
                      size: 44.85,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01971_b1cd2b519a",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892034/thumbnail_DSC_01971_b1cd2b519a.jpg",
                      hash: "thumbnail_DSC_01971_b1cd2b519a",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01971.JPG",
                      path: null,
                      size: 4.14,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01971_b1cd2b519a",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "133",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892112/large_DSC_02146_7e82976b4d.jpg",
                      hash: "large_DSC_02146_7e82976b4d",
                      mime: "image/jpeg",
                      name: "large_DSC02146.JPG",
                      path: null,
                      size: 156.99,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_02146_7e82976b4d",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892112/small_DSC_02146_7e82976b4d.jpg",
                      hash: "small_DSC_02146_7e82976b4d",
                      mime: "image/jpeg",
                      name: "small_DSC02146.JPG",
                      path: null,
                      size: 46.54,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_02146_7e82976b4d",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892112/medium_DSC_02146_7e82976b4d.jpg",
                      hash: "medium_DSC_02146_7e82976b4d",
                      mime: "image/jpeg",
                      name: "medium_DSC02146.JPG",
                      path: null,
                      size: 94.64,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_02146_7e82976b4d",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892111/thumbnail_DSC_02146_7e82976b4d.jpg",
                      hash: "thumbnail_DSC_02146_7e82976b4d",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC02146.JPG",
                      path: null,
                      size: 5.83,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_02146_7e82976b4d",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "134",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892346/large_IMG_5309_b4cfe665cd.jpg",
                      hash: "large_IMG_5309_b4cfe665cd",
                      mime: "image/jpeg",
                      name: "large_IMG_5309.JPG",
                      path: null,
                      size: 92.9,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_IMG_5309_b4cfe665cd",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892347/small_IMG_5309_b4cfe665cd.jpg",
                      hash: "small_IMG_5309_b4cfe665cd",
                      mime: "image/jpeg",
                      name: "small_IMG_5309.JPG",
                      path: null,
                      size: 31.53,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_IMG_5309_b4cfe665cd",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892346/medium_IMG_5309_b4cfe665cd.jpg",
                      hash: "medium_IMG_5309_b4cfe665cd",
                      mime: "image/jpeg",
                      name: "medium_IMG_5309.JPG",
                      path: null,
                      size: 58.91,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_IMG_5309_b4cfe665cd",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892345/thumbnail_IMG_5309_b4cfe665cd.jpg",
                      hash: "thumbnail_IMG_5309_b4cfe665cd",
                      mime: "image/jpeg",
                      name: "thumbnail_IMG_5309.JPG",
                      path: null,
                      size: 10.1,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_IMG_5309_b4cfe665cd",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "130",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892104/large_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692.jpg",
                      hash: "large_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                      mime: "image/jpeg",
                      name: "large_4E7E2F6D-1E11-4E73-BF75-3D54C234EF02.JPG",
                      path: null,
                      size: 125.12,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id:
                          "large_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892106/small_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692.jpg",
                      hash: "small_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                      mime: "image/jpeg",
                      name: "small_4E7E2F6D-1E11-4E73-BF75-3D54C234EF02.JPG",
                      path: null,
                      size: 41.74,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id:
                          "small_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892105/medium_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692.jpg",
                      hash: "medium_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                      mime: "image/jpeg",
                      name: "medium_4E7E2F6D-1E11-4E73-BF75-3D54C234EF02.JPG",
                      path: null,
                      size: 79.41,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id:
                          "medium_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892103/thumbnail_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692.jpg",
                      hash: "thumbnail_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                      mime: "image/jpeg",
                      name: "thumbnail_4E7E2F6D-1E11-4E73-BF75-3D54C234EF02.JPG",
                      path: null,
                      size: 12.02,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id:
                          "thumbnail_4_E7_E2_F6_D_1_E11_4_E73_BF_75_3_D54_C234_EF_02_7e6c175692",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "131",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892107/large_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb.jpg",
                      hash: "large_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                      mime: "image/jpeg",
                      name: "large_ADE4C491-F76B-47CA-B8E9-B3D9DE320DA5.JPG",
                      path: null,
                      size: 175.32,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id:
                          "large_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892110/small_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb.jpg",
                      hash: "small_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                      mime: "image/jpeg",
                      name: "small_ADE4C491-F76B-47CA-B8E9-B3D9DE320DA5.JPG",
                      path: null,
                      size: 52.4,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id:
                          "small_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892110/medium_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb.jpg",
                      hash: "medium_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                      mime: "image/jpeg",
                      name: "medium_ADE4C491-F76B-47CA-B8E9-B3D9DE320DA5.JPG",
                      path: null,
                      size: 105.75,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id:
                          "medium_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892104/thumbnail_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb.jpg",
                      hash: "thumbnail_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                      mime: "image/jpeg",
                      name: "thumbnail_ADE4C491-F76B-47CA-B8E9-B3D9DE320DA5.JPG",
                      path: null,
                      size: 5.93,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id:
                          "thumbnail_ADE_4_C491_F76_B_47_CA_B8_E9_B3_D9_DE_320_DA_5_032e686eeb",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "132",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892111/large_DSC_2629_540f260d0a.jpg",
                      hash: "large_DSC_2629_540f260d0a",
                      mime: "image/jpeg",
                      name: "large_DSC_2629.jpg",
                      path: null,
                      size: 59.14,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_2629_540f260d0a",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892112/small_DSC_2629_540f260d0a.jpg",
                      hash: "small_DSC_2629_540f260d0a",
                      mime: "image/jpeg",
                      name: "small_DSC_2629.jpg",
                      path: null,
                      size: 19.13,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_2629_540f260d0a",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892111/medium_DSC_2629_540f260d0a.jpg",
                      hash: "medium_DSC_2629_540f260d0a",
                      mime: "image/jpeg",
                      name: "medium_DSC_2629.jpg",
                      path: null,
                      size: 37.07,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_2629_540f260d0a",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606892111/thumbnail_DSC_2629_540f260d0a.jpg",
                      hash: "thumbnail_DSC_2629_540f260d0a",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_2629.jpg",
                      path: null,
                      size: 3.26,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_2629_540f260d0a",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
          {
            id: "10",
            title: "Maternity photography",
            slug: "maternity-photography",
            cover: {
              formats: {
                large: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714988/large_DSC_0799_b10be6a8af.jpg",
                  hash: "large_DSC_0799_b10be6a8af",
                  mime: "image/jpeg",
                  name: "large_DSC_0799.jpg",
                  path: null,
                  size: 13.36,
                  width: 665,
                  height: 1000,
                  provider_metadata: {
                    public_id: "large_DSC_0799_b10be6a8af",
                    resource_type: "image",
                  },
                },
                small: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714989/small_DSC_0799_b10be6a8af.jpg",
                  hash: "small_DSC_0799_b10be6a8af",
                  mime: "image/jpeg",
                  name: "small_DSC_0799.jpg",
                  path: null,
                  size: 4.52,
                  width: 333,
                  height: 500,
                  provider_metadata: {
                    public_id: "small_DSC_0799_b10be6a8af",
                    resource_type: "image",
                  },
                },
                medium: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714988/medium_DSC_0799_b10be6a8af.jpg",
                  hash: "medium_DSC_0799_b10be6a8af",
                  mime: "image/jpeg",
                  name: "medium_DSC_0799.jpg",
                  path: null,
                  size: 8.28,
                  width: 499,
                  height: 750,
                  provider_metadata: {
                    public_id: "medium_DSC_0799_b10be6a8af",
                    resource_type: "image",
                  },
                },
                thumbnail: {
                  ext: ".jpg",
                  url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714986/thumbnail_DSC_0799_b10be6a8af.jpg",
                  hash: "thumbnail_DSC_0799_b10be6a8af",
                  mime: "image/jpeg",
                  name: "thumbnail_DSC_0799.jpg",
                  path: null,
                  size: 1.08,
                  width: 104,
                  height: 156,
                  provider_metadata: {
                    public_id: "thumbnail_DSC_0799_b10be6a8af",
                    resource_type: "image",
                  },
                },
              },
              __typename: "UploadFile",
            },
            galery: {
              title: "Maternity",
              name: "Maternity",
              images: [
                {
                  id: "124",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606725397/large_DSC_8250_7cc5dff093.jpg",
                      hash: "large_DSC_8250_7cc5dff093",
                      mime: "image/jpeg",
                      name: "large_DSC_8250.jpg",
                      path: null,
                      size: 47.7,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_8250_7cc5dff093",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606725398/small_DSC_8250_7cc5dff093.jpg",
                      hash: "small_DSC_8250_7cc5dff093",
                      mime: "image/jpeg",
                      name: "small_DSC_8250.jpg",
                      path: null,
                      size: 15.18,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_8250_7cc5dff093",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606725398/medium_DSC_8250_7cc5dff093.jpg",
                      hash: "medium_DSC_8250_7cc5dff093",
                      mime: "image/jpeg",
                      name: "medium_DSC_8250.jpg",
                      path: null,
                      size: 28.32,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_8250_7cc5dff093",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606725397/thumbnail_DSC_8250_7cc5dff093.jpg",
                      hash: "thumbnail_DSC_8250_7cc5dff093",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_8250.jpg",
                      path: null,
                      size: 3.14,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_8250_7cc5dff093",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "64",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714996/large_DSC_6459_52755e4097.jpg",
                      hash: "large_DSC_6459_52755e4097",
                      mime: "image/jpeg",
                      name: "large_DSC_6459.jpg",
                      path: null,
                      size: 13.22,
                      width: 1000,
                      height: 665,
                      provider_metadata: {
                        public_id: "large_DSC_6459_52755e4097",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714996/small_DSC_6459_52755e4097.jpg",
                      hash: "small_DSC_6459_52755e4097",
                      mime: "image/jpeg",
                      name: "small_DSC_6459.jpg",
                      path: null,
                      size: 4.82,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_6459_52755e4097",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714996/medium_DSC_6459_52755e4097.jpg",
                      hash: "medium_DSC_6459_52755e4097",
                      mime: "image/jpeg",
                      name: "medium_DSC_6459.jpg",
                      path: null,
                      size: 8.4,
                      width: 750,
                      height: 499,
                      provider_metadata: {
                        public_id: "medium_DSC_6459_52755e4097",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714995/thumbnail_DSC_6459_52755e4097.jpg",
                      hash: "thumbnail_DSC_6459_52755e4097",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_6459.jpg",
                      path: null,
                      size: 1.89,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_6459_52755e4097",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "63",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714995/large_DSC_6426_11ce6540a0.jpg",
                      hash: "large_DSC_6426_11ce6540a0",
                      mime: "image/jpeg",
                      name: "large_DSC_6426.jpg",
                      path: null,
                      size: 22.88,
                      width: 1000,
                      height: 665,
                      provider_metadata: {
                        public_id: "large_DSC_6426_11ce6540a0",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714995/small_DSC_6426_11ce6540a0.jpg",
                      hash: "small_DSC_6426_11ce6540a0",
                      mime: "image/jpeg",
                      name: "small_DSC_6426.jpg",
                      path: null,
                      size: 7.72,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_6426_11ce6540a0",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714995/medium_DSC_6426_11ce6540a0.jpg",
                      hash: "medium_DSC_6426_11ce6540a0",
                      mime: "image/jpeg",
                      name: "medium_DSC_6426.jpg",
                      path: null,
                      size: 14.17,
                      width: 750,
                      height: 499,
                      provider_metadata: {
                        public_id: "medium_DSC_6426_11ce6540a0",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606714994/thumbnail_DSC_6426_11ce6540a0.jpg",
                      hash: "thumbnail_DSC_6426_11ce6540a0",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC_6426.jpg",
                      path: null,
                      size: 2.74,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_6426_11ce6540a0",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "159",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894988/large_4_6016fab5cf.jpg",
                      hash: "large_4_6016fab5cf",
                      mime: "image/jpeg",
                      name: "large_4.jpg",
                      path: null,
                      size: 44.19,
                      width: 1000,
                      height: 665,
                      provider_metadata: {
                        public_id: "large_4_6016fab5cf",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894989/small_4_6016fab5cf.jpg",
                      hash: "small_4_6016fab5cf",
                      mime: "image/jpeg",
                      name: "small_4.jpg",
                      path: null,
                      size: 14.67,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_4_6016fab5cf",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894988/medium_4_6016fab5cf.jpg",
                      hash: "medium_4_6016fab5cf",
                      mime: "image/jpeg",
                      name: "medium_4.jpg",
                      path: null,
                      size: 26.99,
                      width: 750,
                      height: 499,
                      provider_metadata: {
                        public_id: "medium_4_6016fab5cf",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894986/thumbnail_4_6016fab5cf.jpg",
                      hash: "thumbnail_4_6016fab5cf",
                      mime: "image/jpeg",
                      name: "thumbnail_4.jpg",
                      path: null,
                      size: 5.06,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_4_6016fab5cf",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "160",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894989/large_6_723974af0a.jpg",
                      hash: "large_6_723974af0a",
                      mime: "image/jpeg",
                      name: "large_6.jpg",
                      path: null,
                      size: 61.29,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_6_723974af0a",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894990/small_6_723974af0a.jpg",
                      hash: "small_6_723974af0a",
                      mime: "image/jpeg",
                      name: "small_6.jpg",
                      path: null,
                      size: 21.01,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_6_723974af0a",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894989/medium_6_723974af0a.jpg",
                      hash: "medium_6_723974af0a",
                      mime: "image/jpeg",
                      name: "medium_6.jpg",
                      path: null,
                      size: 38.25,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_6_723974af0a",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894988/thumbnail_6_723974af0a.jpg",
                      hash: "thumbnail_6_723974af0a",
                      mime: "image/jpeg",
                      name: "thumbnail_6.jpg",
                      path: null,
                      size: 3.94,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_6_723974af0a",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "158",
                  formats: {
                    large: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894987/large_5_56568dbcc5.jpg",
                      hash: "large_5_56568dbcc5",
                      mime: "image/jpeg",
                      name: "large_5.jpg",
                      path: null,
                      size: 46.6,
                      width: 665,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_5_56568dbcc5",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894988/small_5_56568dbcc5.jpg",
                      hash: "small_5_56568dbcc5",
                      mime: "image/jpeg",
                      name: "small_5.jpg",
                      path: null,
                      size: 14.6,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_5_56568dbcc5",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894988/medium_5_56568dbcc5.jpg",
                      hash: "medium_5_56568dbcc5",
                      mime: "image/jpeg",
                      name: "medium_5.jpg",
                      path: null,
                      size: 27.86,
                      width: 499,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_5_56568dbcc5",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".jpg",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606894986/thumbnail_5_56568dbcc5.jpg",
                      hash: "thumbnail_5_56568dbcc5",
                      mime: "image/jpeg",
                      name: "thumbnail_5.jpg",
                      path: null,
                      size: 2.77,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_5_56568dbcc5",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "117",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723204/large_DSC_01408_f22633de66.jpg",
                      hash: "large_DSC_01408_f22633de66",
                      mime: "image/jpeg",
                      name: "large_DSC01408.JPG",
                      path: null,
                      size: 26.03,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01408_f22633de66",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723205/small_DSC_01408_f22633de66.jpg",
                      hash: "small_DSC_01408_f22633de66",
                      mime: "image/jpeg",
                      name: "small_DSC01408.JPG",
                      path: null,
                      size: 8.53,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01408_f22633de66",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723205/medium_DSC_01408_f22633de66.jpg",
                      hash: "medium_DSC_01408_f22633de66",
                      mime: "image/jpeg",
                      name: "medium_DSC01408.JPG",
                      path: null,
                      size: 16.22,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01408_f22633de66",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723204/thumbnail_DSC_01408_f22633de66.jpg",
                      hash: "thumbnail_DSC_01408_f22633de66",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01408.JPG",
                      path: null,
                      size: 1.7,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01408_f22633de66",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "118",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723205/large_DSC_01695_316d6fb530.jpg",
                      hash: "large_DSC_01695_316d6fb530",
                      mime: "image/jpeg",
                      name: "large_DSC01695.JPG",
                      path: null,
                      size: 46.71,
                      width: 667,
                      height: 1000,
                      provider_metadata: {
                        public_id: "large_DSC_01695_316d6fb530",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723206/small_DSC_01695_316d6fb530.jpg",
                      hash: "small_DSC_01695_316d6fb530",
                      mime: "image/jpeg",
                      name: "small_DSC01695.JPG",
                      path: null,
                      size: 14.84,
                      width: 333,
                      height: 500,
                      provider_metadata: {
                        public_id: "small_DSC_01695_316d6fb530",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723205/medium_DSC_01695_316d6fb530.jpg",
                      hash: "medium_DSC_01695_316d6fb530",
                      mime: "image/jpeg",
                      name: "medium_DSC01695.JPG",
                      path: null,
                      size: 28.23,
                      width: 500,
                      height: 750,
                      provider_metadata: {
                        public_id: "medium_DSC_01695_316d6fb530",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606723204/thumbnail_DSC_01695_316d6fb530.jpg",
                      hash: "thumbnail_DSC_01695_316d6fb530",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01695.JPG",
                      path: null,
                      size: 2.69,
                      width: 104,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01695_316d6fb530",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
                {
                  id: "43",
                  formats: {
                    large: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606554926/large_DSC_01696_2bdcd59f36.jpg",
                      hash: "large_DSC_01696_2bdcd59f36",
                      mime: "image/jpeg",
                      name: "large_DSC01696.JPG",
                      path: null,
                      size: 49.6,
                      width: 1000,
                      height: 667,
                      provider_metadata: {
                        public_id: "large_DSC_01696_2bdcd59f36",
                        resource_type: "image",
                      },
                    },
                    small: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606554927/small_DSC_01696_2bdcd59f36.jpg",
                      hash: "small_DSC_01696_2bdcd59f36",
                      mime: "image/jpeg",
                      name: "small_DSC01696.JPG",
                      path: null,
                      size: 15.45,
                      width: 500,
                      height: 333,
                      provider_metadata: {
                        public_id: "small_DSC_01696_2bdcd59f36",
                        resource_type: "image",
                      },
                    },
                    medium: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606554927/medium_DSC_01696_2bdcd59f36.jpg",
                      hash: "medium_DSC_01696_2bdcd59f36",
                      mime: "image/jpeg",
                      name: "medium_DSC01696.JPG",
                      path: null,
                      size: 30.04,
                      width: 750,
                      height: 500,
                      provider_metadata: {
                        public_id: "medium_DSC_01696_2bdcd59f36",
                        resource_type: "image",
                      },
                    },
                    thumbnail: {
                      ext: ".JPG",
                      url: "https://res.cloudinary.com/dlgkecjjd/image/upload/v1606554925/thumbnail_DSC_01696_2bdcd59f36.jpg",
                      hash: "thumbnail_DSC_01696_2bdcd59f36",
                      mime: "image/jpeg",
                      name: "thumbnail_DSC01696.JPG",
                      path: null,
                      size: 4.87,
                      width: 234,
                      height: 156,
                      provider_metadata: {
                        public_id: "thumbnail_DSC_01696_2bdcd59f36",
                        resource_type: "image",
                      },
                    },
                  },
                  __typename: "UploadFile",
                },
              ],
              __typename: "Galery",
            },
            content: null,
            __typename: "Album",
          },
        ],
        __typename: "Category",
      },
    },
  },
};

export default kidAndFamilyMock;